import { LinkBoxModal, Linking } from '$ui/Dentally/V2';
import React from 'react';
import { dentallyPanel } from './dentallyPanel.fsm';
import { Patient } from '$state/types';
import { phone } from '$utils';
import { useMachine } from '@xstate5/react';

interface Props {
    id: string;
    onClose: () => void;
    patient: Partial<Patient>;
}

export const DentallyPanel: React.FC<Props> = ({ id, onClose, patient }) => {
    const [state, send] = useMachine(dentallyPanel, {
        input: {
            patientId: id,
        },
    });
    const defaultQuery =
        patient.first_name +
        ' ' +
        patient.last_name +
        ' ' +
        patient.email +
        ' ' +
        phone.format(patient.phone ?? '').replace(/\s/g, '');

    React.useEffect(() => {
        if (
            state.matches('loaded') &&
            state.context.patient?.state === 'unlinked'
        ) {
            suggest();
        }
    }, [state]);

    const search = (query: string) => {
        send({ type: 'search', query: query });
    };
    const suggest = () => {
        send({ type: 'suggest', query: defaultQuery });
    };

    const link = (patientId: string, dentallyId: string) => {
        send({ type: 'link', patientId: patientId, dentallyId: dentallyId });
    };
    const unlink = (patientId: string) => {
        send({ type: 'unlink', patientId: patientId });
    };

    const onView = (id: string) => {
        window.open(`https://app.dentally.co/patients/${id}/details`, '_blank');
    };

    return (
        <LinkBoxModal onClose={onClose}>
            {state.matches('loading') && (
                <Linking state="loading" query={''} id={id} />
            )}
            {state.matches({ linked: 'synced' }) && state.context.patient?.state === 'synced' && (
                    <Linking
                        id={id}
                        query={''}
                        state="linked"
                        patient={state.context.patient.details}
                        financials={state.context.patient?.financials}
                        onLink={(patient_id: string, dentally_id: string) => {
                            link(patient_id, dentally_id);
                        }}
                        onUnlink={(patient_id: string) => {
                            unlink(patient_id);
                        }}
                        onView={(dentally_id: string) => {
                            onView(dentally_id);
                        }}
                    />
                )}

            {state.matches({ linked: 'syncing' }) && state.context.patient?.state === 'syncing' && (
                <Linking
                    id={id}
                    query={''}
                    state="linked"
                    patient={state.context.patient.details}
                    onLink={(patient_id: string, dentally_id: string) => {
                        link(patient_id, dentally_id);
                    }}
                    onUnlink={(patient_id: string) => {
                        unlink(patient_id);
                    }}
                    onView={(dentally_id: string) => {
                        onView(dentally_id);
                    }}
                />
            )}

            {state.matches({ unlinked: 'suggested' }) && (
                <Linking
                    id={id}
                    query={''}
                    state="suggest"
                    patients={state.context.suggestions ?? []}
                    onLink={(patient_id: string, dentally_id: string) => {
                        link(patient_id, dentally_id);
                    }}
                    onUnlink={(patient_id: string) => {
                        unlink(patient_id);
                    }}
                    onChange={(newQuery) => search(newQuery)}
                    onView={(dentally_id: string) => {
                        onView(dentally_id);
                    }}
                />
            )}
            {state.matches({ unlinked: 'searched' }) && (
                <Linking
                    id={id}
                    query={state.context.query ?? ''}
                    state="found"
                    patients={state.context.matches ?? []}
                    onLink={(patient_id: string, dentally_id: string) => {
                        link(patient_id, dentally_id);
                    }}
                    onUnlink={(patient_id: string) => {
                        unlink(patient_id);
                    }}
                    onChange={(newQuery) => search(newQuery)}
                    onView={(dentally_id: string) => {
                        onView(dentally_id);
                    }}
                />
            )}
            {state.matches({ unlinked: 'linking' }) && (
                <Linking
                    id={id}
                    query={''}
                    state="found"
                    patients={state.context.matches ?? []}
                    onLink={(patient_id: string, dentally_id: string) => {
                        link(patient_id, dentally_id);
                    }}
                    onUnlink={(patient_id: string) => {
                        unlink(patient_id);
                    }}
                    onChange={(newQuery) => search(newQuery)}
                    onView={(dentally_id: string) => {
                        onView(dentally_id);
                    }}
                />
            )}
            {state.matches('error') && (
                <Linking state="error" id={id} query={''} />
            )}
        </LinkBoxModal>
    );
};
