import * as React from 'react';
import styled from 'styled-components';
import { applyPadding, mix, scale } from '$ui/Flo/util';
import { applyInputStyle } from '$ui/Flo/Field';
import { Shadow } from '$ui/Flo/Shadow';
import { Circle, Monogram } from '$ui/Flo/Monogram';
import { ColorToken } from '$ui/Flo/types';
import { FinancialBar, FinancialBreakdown } from '$ui/Dentally';
import { FinancialBar as FinancialBarV2 } from '$ui/PatientValues/FinancialBar';
import { FinancialSummary as FinancialSummaryV2 } from '$ui/Dentally/V2';
import * as dentally from '$state/concerns/dentally/patients';
import { PatientFinancials } from '$state/concerns/dentally/patients';
import { Value, ValueState } from '$state/types';
import { EditValueDialog } from '$ui/PatientValues/EditValueDialog';

export interface NameBlockState {
    name: string;
}

export interface Props {
    state: NameBlockState;
    loading?: boolean;
    src?: string;
    dentallyState?: dentally.PatientState;
    financials?: PatientFinancials;
    currency?: string;
    valueState?: ValueState;
    patientValuesEnabled?: boolean;
    onValueSaved?: (data: Value) => void;
}

export const NameBlock = (props: Props) => {
    const {
        state,
        loading,
        src = null,
        dentallyState,
        financials,
        patientValuesEnabled = false,
        valueState,
        currency = 'GBP',
        onValueSaved,
    } = props;

    const displayPatientValues =
        (!dentallyState || dentally.disabled(dentallyState)) &&
        patientValuesEnabled;

    return (
        <Wrapper>
            {src ? (
                <PatientImage
                    name={state.name}
                    src={src}
                    size={6}
                    color={'primary-500'}
                />
            ) : (
                <Monogram
                    color="gray-100"
                    fontColor="primary-500"
                    size={6}
                    name={state.name}
                    loading={loading}
                />
            )}
            <Fields>
                <NameWrapper>
                    <NameField
                        id="patient-name-field"
                        value={state.name}
                        loading={loading}
                    />
                    {displayPatientValues &&
                        currency &&
                        valueState?.state === 'manual' && (
                            <EditValueDialog
                                currency={currency}
                                valueState={valueState}
                                onSave={(data) => onValueSaved?.(data)}
                            />
                        )}
                </NameWrapper>

                {patientValuesEnabled &&
                    valueState &&
                    !loading &&
                    (!dentallyState || dentally.disabled(dentallyState)) &&
                    !financials &&
                    valueState.state === 'manual' && (
                        <FinancialBarV2
                            valueState={valueState}
                            currency={currency}
                        />
                    )}

                {!loading && financials && (
                    <FinancialSummaryV2 financials={financials}>
                        <FinancialBar
                            paid={Number(financials?.total?.paid)}
                            potential={Number(financials?.total?.unpaid)}
                            currency={currency}
                        />
                    </FinancialSummaryV2>
                )}

                {!loading &&
                    dentallyState &&
                    !dentally.disabled(dentallyState) && (
                        <FinancialSummary dentallyState={dentallyState}>
                            <FinancialBar
                                loading={
                                    dentally.syncing(dentallyState) ||
                                    dentally.loading(dentallyState)
                                }
                                paid={
                                    dentally.synced(dentallyState)
                                        ? Number(
                                              dentallyState?.financials.total
                                                  .paid ?? 0,
                                          )
                                        : 0
                                }
                                potential={
                                    dentally.synced(dentallyState)
                                        ? Number(
                                              dentallyState?.financials.total
                                                  .unpaid ?? 0,
                                          )
                                        : 0
                                }
                                currency={currency}
                            />
                        </FinancialSummary>
                    )}
            </Fields>
        </Wrapper>
    );
};

export interface Financial {
    dentallyState: dentally.PatientState;
    children?: React.ReactNode;
}

const FinancialSummary = (props: Financial) => {
    const { dentallyState, children } = props;

    const [open, setOpen] = React.useState(false);

    if (!dentally.synced(dentallyState)) {
        return children;
    }

    const financial = dentally.financials(dentallyState);
    const { total } = financial;

    const handleOpen = () => {
        if (Number(total.paid) === 0 && Number(total.unpaid) === 0) {
            return;
        }

        setOpen(true);
    };

    const handleClose = () => {
        if (open) setOpen(false);
    };

    return (
        <div
            onMouseEnter={() => handleOpen()}
            onMouseLeave={() => handleClose()}
        >
            {children}
            {open && (
                <FinancialWrapper open={open}>
                    <FinancialBreakdown financial={financial} />
                </FinancialWrapper>
            )}
        </div>
    );
};

const FinancialWrapper = styled.div<{
    open: boolean;
}>`
    ${({ open }) => (open ? `display: block;` : `display: none;`)}
    position: absolute;
    height: auto;
    ${mix.padding({ padding: [2] })}
    z-index: 1001;
    left: 0;
    right: 0;
    transform: translateY(-12px);

    & > div {
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: white;
    }
`;

const Fields = styled.div`
    ${({ theme }) => applyPadding({ theme, padding: [0] })};
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 1 auto;
    min-width: 0;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    ${mix.padding({ padding: [2, 3] })};
    min-height: ${mix.unit({ size: 13.5 })};
    gap: ${mix.unit({ size: 2 })};
`;

// Name field
const NameField = (props: {
    id?: string;
    value: string;
    loading?: boolean;
}) => {
    const { value, loading } = props;

    if (loading) {
        return (
            <div style={{ height: '32px', width: '100%' }}>
                <Shadow
                    rounded
                    width={60}
                    height={4}
                    shade="9"
                    style={{ marginBottom: '8px' }}
                />
            </div>
        );
    }

    return (
        <Name data-cy="patient-name-field" bold level="h6" profile="body">
            {value}
        </Name>
    );
};

const Name = styled.div`
    ${applyInputStyle};

    cursor: initial;
    line-height: ${({ theme }) => scale(theme, 4)}px;
    border-radius: 50px 0 0 50px;
    position: relative;
    box-sizing: content-box;
    max-width: 390px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    flex: 0 1 auto;
`;

const PatientImage = (props: {
    name: string;
    src: string;
    size: number;
    color: ColorToken;
}) => {
    const { src, size, color, name } = props;

    return (
        <Circle size={size} color={color} data-qa="patient-image" hidden={true}>
            <Img src={src} alt={name ? name : 'Patient Image'} loading="lazy" />
        </Circle>
    );
};

const Img = styled.img`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    object-fit: cover;
    width: 100%;
    height: 100%;
`;

const NameWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: baseline;
    gap: ${mix.unit({ size: 2 })};
`;
