import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { Icon } from '$ui/Flo/Icon';

interface Props {
    onClick: () => void;
    loading?: boolean;
    error?: string | null;
}

export const SSOLogin = ({ onClick, loading = false, error }: Props) => {

    return (
        <Container>
            <Message>
                You can now login to Leadflo using your Agilio ID.
                If you do not yet have an Agilio ID, contact your practice manager to request an invite.
            </Message>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <ButtonContainer>
                <Button
                    id="login-submit"
                    onClick={onClick}
                >
                    {
                        loading &&
                        (<><Icon
                            hue="white"
                            size={2.5}
                            opacity={1}
                            icon="Loader"
                            spin={true}
                            /> Logging in with AgilioID
                        </>)
                    }
                    {
                        !loading &&
                        (<>
                            <Icon
                                color="white"
                                opacity={1}
                                size={2.5}
                                icon="ExternalLink"
                            />
                            Login with AgilioID
                        </>)
                    }
                </Button>
            </ButtonContainer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${mix.unit({ size: 3 })};
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 auto;
    gap: ${mix.unit({ size: 1 })};
`;

const Button = styled.button`
    ${mix.padding({ padding: [2,4,2,4] })};
    ${mix.type({ level: 'subtitle2' })};
    gap: ${mix.unit({ size: 1.5 })};
    border: 0;
    border-radius: ${mix.unit({ size: 0.5 })};
    width: ${mix.unit({ size: 45 })};
    height: ${mix.unit({ size: 7 })};
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--primary-500);
    font-family: Roboto, sans-serif;
    font-weight: 500;
    font-size: ${mix.unit({ size: 2 })};
    line-height: ${mix.unit({ size: 3 })};
    color: var(--white);

    &:hover {
        cursor: pointer;
    }
`;

const ErrorMessage = styled.div`
    ${mix.bg({ hue: 'red', shade: '6' })};
    ${mix.color({ profile: 'title', dark: true })};
    ${mix.padding({ padding: 2 })};
    ${mix.type({ level: 'subtitle2' })};
`;

const Message = styled.div`
    font-family: Roboto, sans-serif;
    font-weight: 400;
    font-size: ${mix.unit({ size: 2.2 })};
    line-height: ${mix.unit({ size: 4 })};
    letter-spacing: 0;
    text-align: center;
    max-width: 480px;
`;
