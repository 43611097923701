import { Icon, IconName } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { Tooltip } from '$ui/Flo/Tooltip';


export interface Props {
    icon: IconName;
    name: string;
    status: 'linked' | 'unlinked';
    position?: 'left' | 'right';
    onClick?: (integration: string) => void;
}
export const IntegrationItem = (props: Props) => {
    const { icon = 'Dentally', name, status, position = 'left' } = props;
    const [hover, setHover] = React.useState<boolean>(false);

    return (
        <Wrapper
            data-testid={`integration-${name}`}
            key={name}
            onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                props.onClick?.(name);
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            active={status === 'linked'}
        >
            <IntegrationIcon status={status}>
                <Icon icon={"Link"} hue="white" shade="10" />
            </IntegrationIcon>
            <Icon
                data-testid={`integration-${name}-status-${status}`}
                size={4}
                icon={icon}
            />
            <Tooltip align={position == 'right' ? 'left' : 'right'} open={hover} size={'small'} vAlign={'center'}>
               <span>{name} - {status}</span>
            </Tooltip>
        </Wrapper>
    );
};

const Wrapper = styled.div<{ active: boolean; }>`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    ${mix.padding({ padding: [0, 0.2] })};
    border-radius: 5px;
    cursor: pointer;
    border: 1px solid ${({ active }) =>
        active
            ? mix.palette({ hue: 'primary', shade: '8' })
            : mix.palette({ hue: 'grey', shade: '9' })};
`;


const IntegrationIcon = styled.div<{ status: string }>`
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    transform: translate(-35%, -35%);
    display: flex;
    justify-content: center;
    align-items: center;
    ${({ status }) =>
        status === 'linked'
            ? mix.bg({ hue: 'primary', shade: '5' })
            : mix.bg({ hue: 'grey', shade: '5' })}
    & > svg {
        width: 50%;
    }
`;
