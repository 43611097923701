import React from 'react';
import { IconProps } from '$ui/Flo/Icon/Custom/';

export const Dentally = ({
    color = 'currentColor',
    size = 16,
    onClick,
    className,
    style,
}: IconProps) => {
    const fill = color === 'currentColor' ? '#007bff' : color;
    return (
        <svg
            className={className}
            style={style}
            onClick={onClick}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 102.1 30.35"
            width={size}
            height={size}
            fill={fill}
        >
            <title>Dentally</title>
            <path d="M18.21,0,15,16.88a43.36,43.36,0,0,0-.75,6.17H10l.22-2.57h-.06a6.12,6.12,0,0,1-5.2,2.93c-2.69,0-4.93-2.15-4.93-6C0,11.88,4,6.82,10,6.82a6.44,6.44,0,0,1,2.17.35L13.54,0ZM11.49,10.91a3.15,3.15,0,0,0-1.85-.55c-2.79,0-4.77,3.24-4.77,6.29,0,1.92.84,3,2.21,3s3-1.43,3.6-4.45Z" />
            <path d="M29.12,22.14a13.65,13.65,0,0,1-5.91,1.23c-4.54,0-6.88-2.56-6.88-6.68,0-4.84,3.51-9.87,9.25-9.87,3.22,0,5.52,1.78,5.52,4.74,0,4-3.93,5.52-10.29,5.35a3.11,3.11,0,0,0,.49,1.63,3.65,3.65,0,0,0,3.05,1.23,10,10,0,0,0,4.45-1ZM25,10.23a3.94,3.94,0,0,0-3.74,3.27c3.64,0,5.46-.48,5.46-1.91C26.72,10.78,26.07,10.23,25,10.23Z" />
            <path d="M30.19,23.05l2-10.71c.39-2,.65-3.77.85-5.17H37.2L36.88,9.8h.06a6.71,6.71,0,0,1,5.59-3c2.59,0,4.06,1.59,4.06,4.31a19.85,19.85,0,0,1-.26,2.7l-1.76,9.22H39.9l1.65-8.8a10.43,10.43,0,0,0,.17-1.78c0-1.11-.39-1.89-1.56-1.89-1.53,0-3.31,1.92-4,5.42l-1.33,7.05Z" />
            <path d="M55.54,2.73,54.7,7.17h3.38l-.68,3.54H54L53,16.1a11.79,11.79,0,0,0-.26,2A1.17,1.17,0,0,0,54,19.45a8.1,8.1,0,0,0,1.3-.07l-.55,3.67a9.15,9.15,0,0,1-2.76.36c-2.8,0-4.13-1.43-4.13-3.41a15.23,15.23,0,0,1,.33-3l1.23-6.33h-2l.65-3.54h2l.65-3.34Z" />
            <path d="M66.23,23.05c0-.94.13-2,.22-3.15h-.09c-1.47,2.63-3.48,3.51-5.33,3.51-2.86,0-4.8-2.21-4.8-5.52,0-5.36,3.54-11.07,11.36-11.07a18.64,18.64,0,0,1,5.26.78l-1.69,8.31a38.72,38.72,0,0,0-.62,7.14Zm1.36-12.56a6.8,6.8,0,0,0-1.27-.13c-3,0-5.22,3.7-5.22,6.65,0,1.56.61,2.63,1.91,2.63s3.12-1.59,3.83-5.49Z" />
            <path d="M71.91,23.05,76.32,0H81L76.58,23.05Z" />
            <path d="M78.59,23.05,83,0h4.67L83.27,23.05Z" />
            <path d="M91.32,7.17l1,6.92c.23,1.62.39,2.76.52,3.76h.07c.35-.94.71-2,1.39-3.7l2.89-7h4.94l-5.68,11.4c-2.11,4.22-4,7.11-6.11,9.09a10.57,10.57,0,0,1-5.09,2.69l-1-4a9.59,9.59,0,0,0,2.69-1.27,8.24,8.24,0,0,0,2.57-2.76.86.86,0,0,0,.09-.77L86.38,7.17Z" />
        </svg>
    );
};
