// Legacy Styles
import './scss/main.scss';

// React
import * as React from 'react';
import { createRoot } from 'react-dom/client';
import { ReactApp } from './ReactApp';

// Sentry
import * as ReactSentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';

// State
import { store } from '$state';
import { logout } from '$state/concerns/auth';

// Services
import * as config from '@/config/fsm';
import * as versioning from '@/router/fsm';
import api from './api/api';
import * as ws from './ws/service';
import { getAuthService } from '@/auth/fsm';

// Polyfills
import 'cross-fetch/polyfill';

config.start();

config.actor.on('started', ({ config }) => {
    const authService = getAuthService(config.auth_mode);

    api.start(() => {
        const { api_base_url: baseUrl } = config;

        return {
            URL: baseUrl,
            onAuthError: () => {
                store.dispatch(logout());
                authService.logout();
            },
            setApiVersion: () => {},
        };
    });

    versioning.start();

    const startWs = (clientId: number) => {
        ws.start({
            clientId: clientId,
            enabled: config.pusher_enabled,
            secure: config.ws_secure,
            authEndpoint: `${config.api_base_url}/broadcasting/auth`,
            key: config.pusher_key,
            cluster: config.pusher_cluster,
            hostname: config.pusher_host,
            port: config.ws_port,
        });
    };

    authService.onAuthenticated((details) => {
        const { sub, client } = details.payload ?? {};

        if (!sub || !client) {
            return;
        }

        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
            user_id: sub,
            client_name: client.name,
            created_at: client.created_at,
        });

        // Give some time for Tawk to load
        setTimeout(() => {
            try {
                // Try catch as Tawk may be down and the script
                // wouldn't load, so `Tawk_API` may not be available
                Tawk_API.onLoad = function () {
                    Tawk_API.setAttributes(
                        {
                            name: details.email,
                            email: details.email,
                            version: __VERSION__,
                        },
                        (err) =>
                            console.log('Failed to add name to Tawk: ', err),
                    );
                };
            } catch (e) {
                // deliberately do nothing here
            }
        }, 2000);

        startWs(client.id);
    });

    authService.onUnauthenticated(() => {
        store.dispatch(logout());
        ws.stop();
    });

    if (authService.authenticated()) {
        const client = authService.actor.getSnapshot()?.context?.details?.payload.client;
        client && startWs(client.id);
    }

    authService.start();
});

config.actor.on('refreshed', ({ config }) => {
    versioning.actor.send({
        type: 'check',
        config,
    });
});

config.actor.on('started', ({ config }) => {
    const { environment } = config;

    if (environment !== 'development') {
        ReactSentry.init({
            dsn: 'https://a7e2eb4238d84050856bffaa58594834@sentry.io/3731371',
            integrations: [new TracingIntegrations.BrowserTracing()],
            environment: `${environment}:frontend`,
        });
    }
});

declare global {
    interface Window {
        dataLayer?: any[];
    }
}

const container = document.getElementById('app');
const root = createRoot(container!);
root.render(<ReactApp />);
