import { Button } from '$ui/Flo/Button';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled, { css } from 'styled-components';
import DentallySvg from '$resources/dentally.svg';
import { dates } from '$utils';
import { NameBlock } from '$ui/Patient/NameBlock';
import { Shadow } from '$ui/Flo/Shadow';
import { PatientDetails, PatientFinancials } from '$state/concerns/dentally/patients';

export interface LinkBoxProps {
    onView?: () => void;
    onUnlink?: () => void;
    patient?: PatientDetails;
    financials?: PatientFinancials;
}

export const LinkBox = (props: LinkBoxProps) => {
    const { patient, financials } = props;
    const loading = !patient;

    return (
        <Wrapper data-testid="dentally-patient-details">
            <div>
                <NameBlock
                    loading={loading}
                    state={{ name: patient?.firstName + ' ' + patient?.lastName }}
                    financials={financials}
                />
            </div>
            <PatientInfo>
                <Field name="id" label="ID" value={String(patient?.id ?? '')} loading={loading} />

                <Field
                    loading={loading}
                    name="firstName"
                    label="First Name"
                    value={patient?.firstName ?? ''}
                />

                <Field
                    loading={loading}
                    name="lastName"
                    label="Last Name"
                    value={patient?.lastName ?? ''}
                />

                <Field
                    loading={loading}
                    name="emailAddress"
                    label="Email Address"
                    value={patient?.emailAddress ?? ''}
                />

                <Field loading={loading} name="phone" label="Phone" value={patient?.phone ?? ''} />

                <Field
                    loading={loading}
                    name="dateOfBirth"
                    label="Date of Birth"
                    value={patient ? dates.toDate(patient.dateOfBirth) : ''}
                />
            </PatientInfo>
            <PatientAction>
                <Buttons>
                    <Button
                        disabled={loading}
                        mode="outline"
                        hue="primary"
                        icon="ExternalLink"
                        rounded
                        align="center"
                        onClick={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            props.onView?.();
                        }}
                    >
                        View On Dentally
                    </Button>
                    <Button
                        disabled={loading}
                        mode="outline"
                        hue="red"
                        icon="UserX"
                        rounded
                        align="center"
                        onClick={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            props.onUnlink?.();
                        }}
                    >
                        Unlink
                    </Button>
                </Buttons>
                <DentallyLogo />
            </PatientAction>
        </Wrapper>
    );
};

interface LoadedFieldProps {
    loading?: false;
    name: string;
    label: string;
    value: string | null;
}

interface LoadingFieldProps {
    loading: true;
    name: string;
    label: string;
}

type FieldProps = LoadingFieldProps | LoadedFieldProps;

const Field = (props: FieldProps) => {
    const { name, label } = props;

    if (props.loading) {
        return (
            <InfoItem>
                <Label>{label}</Label>
                <Shadow rounded width={75} height={2.625} />
            </InfoItem>
        );
    }

    const { value } = props;

    if (value === null) {
        return null;
    }

    return (
        <InfoItem>
            <Label>{label}</Label>
            <Text data-testid={`value-${name}`}>{value}</Text>
        </InfoItem>
    );
};

const RowStyle = css`
    display: flex;
    flex-direction: row;
`;

const ColumnStyle = css`
    display: flex;
    flex-direction: column;
`;

const HorizontalLineStyle = css`
    ${mix.padding({ padding: [1, 0, 1] })}
    border: 1px solid ${mix.palette({
        hue: 'grey',
        shade: '9',
    })};
    border-bottom: none;
`;

const Wrapper = styled.div`
    ${mix.type({ level: 'body2' })}
    ${mix.color({ profile: 'body' })}
    ${ColumnStyle};
    width: 100%;
    height: 100%;
    position: relative;

    & > div:first-child {
        padding: 0;
    }

    & > div {
        ${mix.padding({ padding: [3] })}
    }

    & > div {
        border: 1px solid
            ${mix.palette({
                hue: 'grey',
                shade: '10',
            })};
        border-bottom: none;
    }

    & > div:last-child {
        border: 1px solid
            ${mix.palette({
                hue: 'grey',
                shade: '10',
            })};
    }
`;

const PatientInfo = styled.div`
    ${HorizontalLineStyle};
    ${ColumnStyle};
`;

const Buttons = styled.div`
    display: inline-flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
`;

const Text = styled.div`
    ${mix.type({ level: 'body2' })}
    width: 100%;
`;

const Label = styled.div`
    ${mix.type({ level: 'body2', bold: true })}
    width: 100%;
`;

const InfoItem = styled.div`
    display: grid;
    grid-template-columns: 30% 70%;
    align-items: center;
    ${mix.margin({ margin: [1, 0] })}
`;

const PatientAction = styled.div`
    ${RowStyle};
    ${mix.gap({ size: 1 })}
    ${HorizontalLineStyle};
    justify-content: space-between;
    align-items: center;
    height: 100%;

    & > svg {
        height: auto;
    }
`;

const DentallyLogo = styled(DentallySvg)`
    ${mix.height({ size: 8 })}
    ${mix.fill({ hue: 'primary', shade: '6' })}
    align-self: flex-end;
`;
