import React from 'react';
import { dates, money } from '$utils';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { PatientFinancials } from '$state/concerns/dentally/patients';
export interface FinancialBreakdownProps {
    financial: PatientFinancials;
    currency?: string;
}

export const FinancialBreakdown = ({
    financial,
    currency = 'GBP'
}: FinancialBreakdownProps) => {
    const { invoices, uninvoicedTreatments: treatments, total } = financial;
    const { paid, unpaid } = invoices;

    const fmt = (value: string) => money.format({ value, currency });

    return (
        <Container>
            <table css="border-collapse: collapse; width: 100%">
                <colgroup>
                    <col width="50%" />
                    <col width="25%" />
                    <col width="25%" />
                </colgroup>
                <thead>
                    <TableHeadRow>
                        <th></th>
                        <Heading>Paid</Heading>
                        <Heading>Potential</Heading>
                    </TableHeadRow>
                </thead>
                <tbody>
                    <Section name="paid-invoices" title="Invoices (Paid)" paid>
                        {paid.map((invoice) => ({
                            id: invoice.id,
                            label: dates.toDate(invoice.date),
                            value: fmt(invoice.amount)
                        }))}
                    </Section>

                    <Section name="unpaid-invoices" title="Invoices (Unpaid)">
                        {unpaid.map((invoice) => ({
                            id: invoice.id,
                            label: dates.toDate(invoice.date),
                            value: fmt(invoice.amount)
                        }))}
                    </Section>

                    <Section name="treatment" title="Treatments">
                        {treatments.map((treatment) => ({
                            id: treatment.id,
                            label: treatment.name,
                            value: fmt(treatment.amount),
                            quantity: treatment.quantity
                        }))}
                    </Section>
                </tbody>
                <tfoot>
                    <TableFootRow>
                        <th></th>
                        <th>
                            <Total paid>{fmt(total.paid)}</Total>
                        </th>
                        <th>
                            <Total>{fmt(total.unpaid)}</Total>
                        </th>
                    </TableFootRow>
                </tfoot>
            </table>
        </Container>
    );
};

interface SectionProps {
    name: string;
    title: string;
    paid?: boolean;
    children: Array<{
        id: number;
        label: string;
        value: string;
        quantity?: string;
    }>;
}

const Section = (props: SectionProps) => {
    const { name, title, paid = false, children } = props;

    if (children.length === 0) {
        return null;
    }

    return (
        <>
            <SectionHeadRow>
                <Heading align="left" colSpan={3}>
                    {title}
                </Heading>
            </SectionHeadRow>
            {children.map((child, index) => (
                <LineItem
                    key={name + child.id}
                    last={index === children.length - 1}
                >
                    <td>
                        {child.label}{' '}
                        {!!child.quantity && (
                            <Count>(x{child.quantity})</Count>
                        )}
                    </td>
                    <LineItemCell paid>{paid ? child.value : ''}</LineItemCell>
                    <LineItemCell>{!paid ? child.value : ''}</LineItemCell>
                </LineItem>
            ))}
        </>
    );
};

const Container = styled.div`
    ${mix.padding({ padding: 2 })};
    ${mix.type({ level: 'small' })};
    max-width: ${mix.unit({ size: 62.5 })};
    color: hsla(0, 0%, 30%, 1);
    border: 1px solid hsla(228, 20%, 95%, 1);
    border-radius: 6px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.08);
    max-height: ${mix.unit({ size: 35 })};
    overflow-y: auto;
`;

const TableHeadRow = styled.tr`
    border-bottom: 2px solid #f0f1f5;
`;

const TableFootRow = styled.tr`
    border-top: 2px solid #f0f1f5;

    th {
        ${mix.padding({ padding: [2, 0.5] })};
    }
`;

const SectionHeadRow = styled.tr`
    border-top: 1px solid #f0f1f5;
`;

const Heading = styled.th<{ align?: 'left' | 'center' }>`
    ${mix.padding({ padding: [2, 0, 1] })};

    text-transform: uppercase;
    letter-spacing: 0.5px;

    ${({ align = 'center' }) =>
        css`
            text-align: ${align};
        `}
`;

const LineItem = styled.tr<{ last?: boolean }>`
    td {
        ${mix.padding({ padding: [0, 0, 1] })};

        ${({ last = false }) =>
            last &&
            css`
                ${mix.padding({ padding: [0, 0, 2] })};
            `}
    }
`;

const LineItemCell = styled.td<{ paid?: boolean }>`
    text-align: center;
    ${({ paid = false }) => css`
        color: ${paid ? 'hsla(151, 80%, 40%, 1)' : 'hsla(212, 95%, 57%, 1)'};
    `}
`;

const Total = styled.div<{ paid?: boolean }>`
    ${mix.padding({ padding: 1 })};
    font-weight: 400;
    border-radius: 4px;
    background: hsla(212, 95%, 57%, 0.08);
    color: hsla(212, 95%, 57%, 1);
    text-align: center;

    ${({ paid = false }) =>
        paid &&
        css`
            background: hsla(151, 80%, 40%, 0.08);
            color: hsla(151, 80%, 40%, 1);
        `}
`;

const Count = styled.span`
    ${mix.type({ level: 'small' })};
    ${mix.color({ profile: 'disabled' })}
`;
