import { IconName } from '$ui/Flo/Icon';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { IntegrationItem } from '$ui/Patient/Integrations/IntegrationItem';


interface Props {
    integrations: {
        [key: string]: {
            icon: IconName;
            name: string;
            status: 'linked' | 'unlinked';
        }
    };
    position?: 'left' | 'right';
    onClick?: (integration: string) => void;
}

export const IntegrationsBar = (props: Props) => {
    const { integrations, position, onClick } = props;

    const IntegrationItems = Object.entries(integrations).map(([key, value]) => {
        return <IntegrationItem key={key} status={value.status} icon={value.icon} name={value.name} position={position} onClick={onClick} />;
    });

    return (
        <Wrapper>
            {IntegrationItems}
        </Wrapper>
    );
}

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${mix.unit({size: 1})};
    padding: ${mix.unit({size: 1})};
`;
