import { RefObject, useEffect, useRef } from 'react';

/**
 * Sets the page title. Doesn't do much now but eventually we will want to
 * render notification state in the page title, so it makes sense to have a
 * hook for it to build on.
 *
 * @param title
 */
export const usePageTitle = (title: string) => {
    useEffect(() => {
        document.title = `${title} - Leadflo`;
    }, [title]);
};

interface HoverProps<T extends HTMLElement> {
    ref?: RefObject<T>;
    onEnter?: () => void;
    onLeave?: () => void;
}

export const useHover = <T extends HTMLElement>({
    ref,
    onEnter = () => null,
    onLeave = () => null,
}: HoverProps<T>) => {
    const internalRef = useRef<T | null>(null);

    useEffect(() => {
        const actualRef = ref ?? internalRef;
        const node = actualRef.current;

        if (node) {
            node.addEventListener('mouseenter', onEnter);
            node.addEventListener('mouseleave', onLeave);
        }

        return () => {
            if (node) {
                node.removeEventListener('mouseenter', onEnter);
                node.removeEventListener('mouseleave', onLeave);
            }
        };
    }, [ref, onEnter, onLeave]);

    return { ref: ref ?? internalRef };
};

type Handler = (event: MouseEvent | TouchEvent) => void;

type Options<T extends HTMLElement> = {
    ref: RefObject<T>;
    fn: Handler;
};

export function useClickOutside<T extends HTMLElement = HTMLElement>(
    opts: Options<T>,
): RefObject<T> {
    const ref = opts.ref;

    useEffect(() => {
        const listener = (event: MouseEvent | TouchEvent) => {
            const target = event.target as Node;
            const element = ref.current;

            // Do nothing if:
            // 1. Click was inside the element
            // 2. Element doesn't exist
            // 3. Target doesn't exist (shouldn't happen but type safety)
            if (!element || !target || element.contains(target)) {
                return;
            }

            opts.fn(event);
        };

        // Handle both mouse and touch events
        document.addEventListener('mousedown', listener);
        document.addEventListener('touchstart', listener);

        return () => {
            document.removeEventListener('mousedown', listener);
            document.removeEventListener('touchstart', listener);
        };
    }, [opts.fn]);

    return ref;
}
