import { Button } from '$ui/Flo/Button';
import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';

export interface LinkBoxModalProps {
    children?: React.ReactNode;
    onClose?: () => void;
}

export const LinkBoxModal = (props: LinkBoxModalProps) => {
    const { children } = props;

    const modalRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (
                modalRef.current &&
                !modalRef.current.contains(event.target as Node)
            ) {
                props.onClose?.();
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [props.onClose]);

    return (
        <Wrapper>
            <Filler />
            <CloseButton>
                <Button
                    mode="solid"
                    icon="X"
                    rounded
                    hue="primary"
                    onClick={() => props.onClose?.()}
                    align="center"
                >
                    Close
                </Button>
            </CloseButton>
            <ModalBody ref={modalRef}>{children}</ModalBody>
        </Wrapper>
    );
};

/*
 * parents of this component should have position: relative, width and height
 * so that this component can attach to it
 */

const Wrapper = styled.div`
    ${mix.type({ level: 'body2' })}
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    ${mix.bg({ hue: 'grey', shade: '5', alpha: 0.5 })}
`;

const Filler = styled.div`
    flex: 1;
`;

const CloseButton = styled.div`
    margin-left: auto;
    ${mix.padding({ padding: [1] })}
`;

const ModalBody = styled.div`
    overflow: auto;
    background: white;
    display: flex;
`;
