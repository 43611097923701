import { useQuery } from '@tanstack/react-query';
import { api } from '$api';
import { Match } from '$api/dentally/match';

const queryKey = 'dentally-linking-suggestions';

export const useDentallySuggestions = (query: string) => {
    return useQuery({
        queryKey: [queryKey, query],
        queryFn: async () => {
            const suggestions = await api
                .get(`/dentally/patients`, {
                    params: {
                        query: query,
                        confidence: 0.05, // confidence threshold
                    },
                })
                .then(({ data }) => data);

            return suggestions.matches as Match[]
        }
    });
};
