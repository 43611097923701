import { ValueBar } from '$ui/Flo/ValueBar';
import React from 'react';
import { ValueState } from '$state/types';

export interface FinancialBarProps {
    currency: string;
    valueState: ValueState;
}

export const FinancialBar = ({ currency, valueState }: FinancialBarProps) => {
    if (valueState.state === 'syncing') {
        return <ValueBar id="patient-financial-bar" state="loading" />;
    }

    if (valueState.state === 'unlinked') {
        return <ValueBar id="patient-financial-bar" state="unlinked" />;
    }

    const { value } = valueState;

    return (
        <ValueBar
            id="patient-financial-bar"
            state="loaded"
            values={[
                {
                    key: 'Paid',
                    value: value.paid,
                    hue: 'green',
                    shade: '5',
                    prefix: currency,
                },
                {
                    key: 'Accepted',
                    value: value.accepted,
                    hue: 'primary',
                    shade: '5',
                    prefix: currency,
                },
                {
                    key: 'Proposed',
                    value: value.proposed,
                    hue: 'grey',
                    shade: '5',
                    prefix: currency,
                },
            ]}
        />
    );
};
