import { SnoozeStatus } from '$state/types/snooze';
import { FlowNodeType, NextActionContext } from '$types';
import { Icon } from '$ui/Flo/Icon';
import { Text } from '$ui/Flo/Text';
import { applyPadding, scale } from '$ui/Flo/util';
import { dates } from '$utils';
import React from 'react';
import styled from 'styled-components';
import { Step } from './Step';
import { StepProps } from './types';
import { endType } from '$ui/Snooze';
import { Delay } from '$ui/Settings/Pipeline/hooks';
import { formatDistance } from 'date-fns';

// onChange should never be called from this step
export interface Props extends StepProps<never> {
    type: FlowNodeType.confirm;
    context: Partial<NextActionContext>;
    stage: string | null;
    snooze?: SnoozeStatus | null;
    delay: Delay;
}

const List = styled.ul`
    ${({ theme }) => applyPadding({ theme, padding: [1, 6] })}
    list-style: none;
`;

const ListItem = styled.li`
    ${({ theme }) => applyPadding({ theme, padding: [0, 0, 0, 4] })}
    position: relative;
    margin-bottom: ${({ theme }) => scale(theme, 2)}px;
`;

const ListIcon = styled(Icon)`
    position: absolute;
    left: 0;
    top: 6px;
`;

const Items = ({ items }: { items: string[] }) => {
    return (
        <List>
            {items.map((item, i) => (
                <ListItem key={i}>
                    <ListIcon icon="CheckCircle" hue="green" />
                    <Text>{item}</Text>
                </ListItem>
            ))}
        </List>
    );
};

export const getBookingType = (stage: string): string => {
    const stages = {
        inTx: 'booked their next treatment appointment',
        txPlanConsult: 'booked treatment',
        consultation:
            'decided to go ahead with treatment. We will next update their treatment progress',
    };

    return stages[stage] ?? 'booked a consultation';
};

export const getCallbackItems = (
    stage: string | null,
    date = '',
    delay: Delay,
): string[] => {
    if (stage === 'callback3') {
        return [
            'The patient did not answer',
            'We will not attempt to contact them again',
        ];
    }

    const duration = formatDistance(0, delay.seconds * 1000);

    return [
        'The patient did not answer',
        `We will call again in ${duration} on ${date}`,
    ];
};

export const getConsultType = (stage: string | null): string => {
    if (stage === 'txPlanConsult') {
        return 'treatment plan consultation';
    }
    return 'consultation';
};

export const generateItems = (
    stage: string | null,
    { nextAction, date }: Partial<NextActionContext>,
    delay: Delay,
): string[] => {
    if (!nextAction) {
        throw new Error('Next action must be set at confirmation.');
    }

    const formattedDate = dates.long(date);

    const items = {
        booking: [
            `The patient has ${getBookingType(
                stage ?? '',
            )} on ${formattedDate}`,
        ],

        consultation: [
            `The patient has booked a consultation on ${formattedDate}`,
        ],

        txPlanBooking: [
            `The patient has booked a treatment plan consultation on ${formattedDate}`,
        ],

        complete: [
            `The patient has completed treatment`,
            `They do not need any further appointments`,
        ],

        wonSale: [
            `The patient has booked or completed treatment`,
            `They do not need any further action within Leadflo`,
        ],

        rearrangedConsult: [
            `The patient has rearranged their ${getConsultType(
                stage,
            )} to ${formattedDate}`,
        ],

        cancelledConsult: [
            `The patient has cancelled their consultation without rescheduling`,
            `We will follow up with them on ${formattedDate}`,
        ],

        rearrangedAppt: [
            `The patient has rearranged their appointment to ${formattedDate}`,
        ],

        callback: getCallbackItems(stage, formattedDate, delay),

        lost: [
            'The patient never wants treatment with us',
            'We should not contact them at all, by phone or email',
        ],

        maybeFuture: [
            'The patient is not interested in treatment right now',
            'We will not contact them by phone until they contact us',
            'The patient may be interested in the future',
        ],

        didNotAttend: ['The patient did not attend their appointment'],

        wrongNumber: [
            'The patient did not provide a correct phone number',
            'We will wait for the patient to provide a correct number',
        ],

        thinking: [
            'You have contacted the patient',
            'They need more time to think about starting treatment',
            `We will next contact them on ${formattedDate}`,
        ],

        working: [
            'You have contacted the patient',
            'They need more time to think about starting treatment',
            `We will next contact them on ${formattedDate}`,
        ],

        blocked: [
            'You do not want to do business with this person',
            'They will not be able to contact us again',
        ],
    };

    return items[nextAction] ?? [];
};

const generateNote = ({ note }: Partial<NextActionContext>): string[] => {
    if (!note) {
        return [];
    }

    return [`You noted: "${note}"`];
};

const generateSnoozeStatus = (
    snooze: SnoozeStatus | null | undefined,
    { endSnooze }: Partial<NextActionContext>,
): string[] => {
    if (!snooze) {
        return [];
    }

    const verb = endType(snooze.state).toLowerCase();

    if (endSnooze) {
        return [`You want to ${verb} the patient's snooze`];
    }

    return [`You do not want to ${verb} the patient's snooze`];
};

const generateDentallyStatus = ({
    dentally,
}: Partial<NextActionContext>): string[] => {
    if (!dentally) {
        return [];
    }

    const { link } = dentally;

    if (link) {
        return [`You want to link the patient with Dentally`];
    }

    return [];
};

export const ConfirmationStep = (props: Props) => {
    const { context, stage, snooze, delay } = props;

    return (
        <div data-cy="confirmation-step">
            <Step {...props}>
                <Items
                    items={[
                        ...generateItems(stage, context, delay),
                        ...generateNote(context),
                        ...generateSnoozeStatus(snooze, context),
                        ...generateDentallyStatus(context),
                    ]}
                />
            </Step>
        </div>
    );
};
