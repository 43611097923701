import React from 'react';
import { Sidebar } from '../Sidebar';
import { useHistory, useLocation } from 'react-router';
import { useAppSelector } from '$state';
import { anyIntegrationUnhealthy } from '$state/concerns/integrations';
import { useAuthMode, useBaseURL } from '@/config/react';
import { getAuthService } from '@/auth/fsm';

export const RoutingSidebar = () => {
    const history = useHistory();
    const location = useLocation();
    const settingsAlert = useAppSelector(anyIntegrationUnhealthy);
    const authMode = useAuthMode();
    const apiBaseUrl = useBaseURL();

    const navigate = (dest: string) => history.push(dest);

    const signOut = () => {
        if (!authMode) {
            console.warn(
                'Attempting to logout when auth_mode configuration' +
                    ' value is not set.',
            );
            return;
        }

        if (!apiBaseUrl) {
            throw new Error(
                'Config must be injected by this point. This ' +
                'is a serious programming error and the application is ' +
                'in an invalid state.',
            );
        }

        getAuthService(authMode).logout({ apiBaseUrl });
    };

    return (
        <Sidebar
            navigate={navigate}
            signOut={signOut}
            currentRoute={location.pathname}
            alert={settingsAlert ? 'settings' : ''}
        />
    );
};
