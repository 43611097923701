import { AuthArgs, Client, ClientSettings } from '$state/types';
import { api } from './api';

export interface InitPayload {
    client: Client;
    settings: ClientSettings;
    features: string[];
    role: string;
    tx_types: string[];
    pipeline: string;
    labels: string[];
    labels_history: string[];
    subscriber_id: string | null;
    subscriber_hash: string | null;
    currency: {
        code: string;
        symbol: string;
        name: string;
    };
}

export interface AuthPayload extends InitPayload {
    token: string;
}

export const init = (): Promise<InitPayload> => {
    return api.get('/v3/init').then((res) => res.data);
};

export const login = (details: AuthArgs): Promise<AuthPayload> => {
    return api.post('/authenticate', details).then((res) => res.data);
};
