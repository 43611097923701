import React from 'react';
import styled from 'styled-components';
import { money } from '$utils';
import { mix } from '$ui/Flo/util';
import { AggregateValue } from '$state/queries/reporting';

// Define type-to-color mappings based on the SVGs
const typeConfig = {
    estimated: {
        bgColor: 'var(--gray-100)',
        textColor: 'var(--gray-500)',
        label: 'Estimated',
    },
    proposed: {
        bgColor: 'var(--primary-050)',
        textColor: 'var(--primary-500)',
        label: 'Proposed',
    },
    accepted: {
        bgColor: 'var(--success-050)',
        textColor: 'var(--success-500)',
        label: 'Accepted',
    },
    lost: {
        bgColor: 'var(--error-050)',
        textColor: 'var(--error-500)',
        label: 'Lost',
    },
};

interface ValueProps {
    value: AggregateValue;
    currency?: string;
    className?: string;
}

export const Value: React.FC<ValueProps> = ({
    value,
    currency = 'GBP',
    className,
}) => {
    const config = typeConfig[value.type];
    const formattedValue = money.format({
        value: value.value,
        currency,
        compact: true,
        fractionDigits: 0,
        maximumFractionDigits: 2,
    });

    let sign = '';

    if (value.difference?.direction === 'positive') {
        sign = '+';
    }

    if (value.difference?.direction === 'negative') {
        sign = '-';
    }

    return (
        <Container className={className}>
            <ValuePart>
                <ValueText>{formattedValue}</ValueText>
                {value.difference && (
                    <DifferenceIndicator direction={value.difference.direction}>
                        {sign}
                        {money.formatWithoutCurrency({
                            value: value.difference.value,
                            fractionDigits: 0,
                            maximumFractionDigits: 2,
                            compact: true,
                        })}
                    </DifferenceIndicator>
                )}
            </ValuePart>
            <TypeBadge bgColor={config.bgColor} textColor={config.textColor}>
                {config.label}
            </TypeBadge>
        </Container>
    );
};

// Styled components
const Container = styled.div`
    display: inline-flex;
    align-items: center;
    ${mix.gap({ size: 1.5 })}
    font-family: 'Roboto', sans-serif;
`;

const ValuePart = styled.div`
    display: flex;
    align-items: center;
    ${mix.gap({ size: 0.5 })}
`;

const ValueText = styled.span`
    font-size: 16px;
    font-weight: 500;
    color: var(--gray-600);
`;

const DifferenceIndicator = styled.span<{
    direction: 'positive' | 'negative' | 'neutral';
}>`
    font-size: 12px;
    font-weight: 500;
    color: ${(props) =>
        props.direction === 'positive'
            ? 'var(--success-500)'
            : props.direction === 'negative'
            ? 'var(--error-500)'
            : 'var(--gray-500)'};
    ${mix.margin({ margin: [0, 0, 0, 0.5] })}
`;

interface TypeBadgeProps {
    bgColor: string;
    textColor: string;
}

const TypeBadge = styled.div<TypeBadgeProps>`
    background-color: ${(props) => props.bgColor};
    color: ${(props) => props.textColor};
    ${mix.padding({ padding: [0.5, 1.5] })}
    border-radius: 4px;
    font-size: 14px;
    font-weight: 500;
    ${mix.height({ size: 3 })}
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default Value;
