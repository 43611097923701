import React from 'react';
import { Align, Hue, Shade } from '$ui/Flo/types';
import { Metric } from './Metric';
import { useAppSelector } from '$state/hooks';
import { selectMetric } from '$state/queries/reporting';
import { useHistory } from 'react-router';
import { History } from 'history';
import { integrationHealthy } from '$state/concerns/integrations';
import { withState } from '$state';
import { featureEnabled } from '$state/queries/features';
import { selectCurrencyCode } from '$state/queries/auth';

interface Props {
    id: string | null;
    report: string;
    name: string;
    label: string;
    hue?: Hue;
    shade?: Shade;
    tooltip?: string;
    tooltipAlign?: Align;
    fill?: boolean;
    unit?: 'absolute' | 'interval';
}

export const ConnectedMetric = (props: Props) => {
    const metric = useAppSelector(
        withState(selectMetric, props.report, props.name),
    );
    const dentallyHealthy = useAppSelector(
        withState(integrationHealthy, 'dentally'),
    );
    const aggregateValueEnabled = useAppSelector(
        withState(featureEnabled, 'aggregate-value'),
    );

    const currency = useAppSelector(selectCurrencyCode);
    const history = useHistory();

    return (
        <Metric
            {...props}
            {...metric}
            id={props.id}
            onClick={props.id ? openMetric(history, props.id) : undefined}
            currency={currency}
            aggregateValue={
                !dentallyHealthy && aggregateValueEnabled // TODO: Move to selector
                    ? metric.aggregateValue
                    : undefined
            }
        />
    );
};

interface BrokenProps extends Props {
    name: 'lost' | 'losses';
}

export const ConnectedBrokenMetric = (props: BrokenProps) => {
    const metric = useAppSelector(
        withState(selectMetric, props.report, props.name),
    );

    const notInterested = useAppSelector(
        withState(selectMetric, props.report, 'not_interested'),
    );

    const maybeFuture = useAppSelector(
        withState(selectMetric, props.report, 'maybe_future'),
    );

    const blocked = useAppSelector(
        withState(selectMetric, props.report, 'blocked'),
    );

    const currency = useAppSelector(selectCurrencyCode);
    const history = useHistory();

    // TODO: Refactor these so they follow lost, maybeFuture and blocked
    const breakdown = [
        { value: notInterested.value, label: 'Will never be interested' },
        { value: maybeFuture.value, label: 'Not interested right now' },
        { value: blocked.value, label: 'Blocked', uncounted: true },
    ];

    return (
        <Metric
            {...props}
            {...metric}
            breakdown={breakdown}
            id={props.id}
            onClick={props.id ? openMetric(history, props.id) : undefined}
            currency={currency}
        />
    );
};

const openMetric = (history: History, report: string) => () => {
    history.push(`/patients/${report}`);
};
