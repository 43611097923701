import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { ManualValueState, Value } from '$state/types';
import { EditValueForm } from '$ui/PatientValues/EditValueForm';

type Props = {
    currency: string;
    valueState: ManualValueState;
    onSave?: (value: Value) => void;
};

export const EditValueDialog = ({ currency, valueState, onSave }: Props) => {
    const ref = useRef<HTMLDivElement>(null);

    const [open, setOpen] = useState(false);

    const parts = new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency,
    });

    const currencyPart = parts
        .formatToParts(0)
        .find((part) => part.type === 'currency');

    const handleSave = (data: Value) => {
        setOpen(false);
        onSave?.(data);
    };

    return (
        <Container ref={ref}>
            <Button
                data-cy="edit-patient-values-button"
                onClick={() => {
                    setOpen(true);
                }}
            >
                Edit values
            </Button>
            {open && (
                <Dialog>
                    <EditValueForm
                        containerRef={ref}
                        value={valueState.value}
                        currency={currencyPart?.value ?? '£'}
                        onCancel={() => setOpen(false)}
                        onSave={handleSave}
                    />
                </Dialog>
            )}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    flex: 0 0 auto;
`;

const Button = styled.div`
    cursor: pointer;
    color: var(--primary-500);
    ${mix.type({ level: 'small', bold: true })};
    text-transform: lowercase;
    user-select: none;
`;

const Dialog = styled.div`
    ${mix.width({ size: 50 })};
    border: 1px solid var(--gray-200);
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.1);
    border-radius: ${mix.unit({ size: 1 })};
    position: absolute;
    top: calc(100% + 11px);
    right: 0px;
    background: white;
    z-index: 200;
`;
