import { mix } from '$ui/Flo/util';
import React from 'react';
import styled from 'styled-components';
import { Field } from './Field';
import { Credentials } from '@/auth/modes/types';

interface Props {
    onSubmit: (details: Credentials) => void;
    error?: string | null;
    isAuthenticating?: boolean;
}

const toHumanReadableError = (error: string | null) => {
    switch (error) {
        case null:
            return null;

        case 'Network error':
            return (
                'Could not connect to Leadflo. Are you connected to' +
                ' the internet?'
            );

        case 'client inactive':
            return (
                'Sorry, it looks like your account is disabled.' +
                ' Contact support so we can get you back online :)'
            );

        case 'invalid_credentials':
            return 'Your email or password is incorrect';

        case 'too_many_attempts':
            return (
                'You have entered an incorrect email/password too' +
                ' many times. Please wait up to 30 minutes and try again'
            );

        case 'session_expired':
            return 'Your session has expired';

        default:
            return 'unknown_error';
    }
};

/**
 * Implemented as a separate form so we could, if they become unauthenticated,
 * show a modal instead of redirecting them to the login page in-app.
 */
export const Form = ({
    onSubmit,
    isAuthenticating = false,
    error = null,
}: Props) => {
    const [email, setEmail] = React.useState<string>('');
    const [password, setPassword] = React.useState<string>('');

    const _onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSubmit({
            email,
            password,
        });
    };

    return (
        <Container onSubmit={_onSubmit}>
            {error && (
                <ErrorMessage>{toHumanReadableError(error)}</ErrorMessage>
            )}
            <Field
                label="Email"
                type="email"
                placeholder="Enter your email address"
                onChange={setEmail}
                value={email}
                disabled={isAuthenticating}
            />
            <Field
                label="Password"
                type="password"
                placeholder="Enter your password"
                onChange={setPassword}
                value={password}
                disabled={isAuthenticating}
            />
            <Button id="login-submit" disabled={isAuthenticating}>
                Login
            </Button>
        </Container>
    );
};

const Container = styled.form`
    display: flex;
    flex-direction: column;
    gap: ${mix.unit({ size: 3 })};
`;

const Button = styled.button`
    ${mix.bg({ hue: 'primary', shade: '6' })};
    ${mix.padding({ padding: 2 })};
    ${mix.color({ profile: 'title', dark: true })};
    ${mix.type({ level: 'subtitle2' })};
    border: 0;
    border-radius: 3px;
    width: ${mix.unit({ size: 40 })};
    line-height: 1;

    &:hover {
        cursor: pointer;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }
`;

const ErrorMessage = styled.div`
    ${mix.bg({ hue: 'red', shade: '6' })};
    ${mix.color({ profile: 'title', dark: true })};
    ${mix.padding({ padding: 2 })};
    ${mix.type({ level: 'subtitle2' })};
`;
