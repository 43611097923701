import React from 'react';
import { FlowNodeType, FlowState, NextActionContext, NodeMap } from '$types';
import {
    addCallBackDelay,
    back,
    changeNextAction,
    context,
    currentNode,
    final,
    first,
    next,
    proceedable,
    start,
} from '$state/flows/actions/queries';
import { ScheduleStep } from './Steps/ScheduleStep';
import { ChoicesStep, ChoiceValue } from './Steps/ChoicesStep';
import { NoteStep } from './Steps/NoteStep';
import { ConfirmationStep } from './Steps/ConfirmationStep';
import styled, { css, keyframes } from 'styled-components';
import { SourceStep } from './Steps/SourceStep';
import { SnoozeStatus } from '$state/types/snooze';
import { EndSnoozeStep } from './Steps/EndSnoozeStep';
import { Delay, Settings } from '$ui/Settings/Pipeline/hooks';
import { LinkPatientStep } from '$ui/Actions/Flow/Steps/LinkPatientStep/LinkPatientStep';
import { Patient } from '$state/types';

interface Props {
    stage: string | null;
    onComplete: (context: Partial<NextActionContext>) => void;
    onCancel: () => void;
    initialNode: string;
    nodes: NodeMap;
    leadSource: string;
    leadSources: string[];
    snooze?: SnoozeStatus | null;
    settings?: Settings;
    dentallyActive?: boolean;
    dentallyLinked?: 'linked' | 'unlinked';
    patient?: Patient;
}

enum TransitionDirection {
    NONE = 'none',
    BACK = 'back',
    NEXT = 'next',
}

export const Flow = (props: Props) => {
    const {
        onComplete,
        onCancel,
        initialNode,
        stage,
        nodes,
        leadSource,
        leadSources,
        snooze,
        settings,
        dentallyActive,
        dentallyLinked,
        patient
    } = props;
    const [state, setState] = React.useState<FlowState>(
        start(nodes, initialNode),
    );
    const [dir, setDirection] = React.useState<TransitionDirection>(
        TransitionDirection.NONE,
    );

    const node = currentNode(nodes, state);
    const isFirst = first(state);
    const isFinal = final(state);
    const isProceedable = proceedable(state);

    const onNext = () => {
        setState(next(nodes, state));
        setDirection(TransitionDirection.NEXT);
    };

    const onBack = () => {
        if (state.previous == null) {
            onCancel();
            return;
        }
        const newState = back(nodes, state);
        if (newState == null) {
            onCancel();
            return;
        }
        setState(newState);
        setDirection(TransitionDirection.BACK);
    };

    const commonProps = {
        key: node.key,
        first: isFirst,
        final: isFinal,
        proceedable: isProceedable,
        onNext: onNext,
        onBack: onBack,
    };

    const updateContext = (
        payload: Partial<NextActionContext> | ChoiceValue,
    ) => {
        if (node.type === FlowNodeType.choices) {
            payload = payload as ChoiceValue;
            setState({
                ...state,
                provisionalNext: payload.target ?? null,
                context: node.mapping ? { [node.mapping]: payload.value } : {},
            });
            return;
        }
        setState({
            ...state,
            context: payload as Partial<NextActionContext>,
        });
    };

    React.useEffect(() => {
        if (node.type === FlowNodeType.finish) {
            const action = context(state);
            onComplete(action);
        }
    }, [node.type]);

    const delay: Delay = settings?.call_back_delays[stage ?? 'newLead'] ?? {
        stage: 'newLead',
        seconds: 5 * 24 * 60 * 60,
    };

    // Some nodes dont make to steps, but instead cause changes to the pipeline
    // in some way, and should be registered here
    switch (node.type) {
        case FlowNodeType.addDays:
            setState(next(nodes, addCallBackDelay(state, delay)));
            break;
        case FlowNodeType.changeStage:
            setState(next(nodes, changeNextAction(state, node.to)));
            break;
        case FlowNodeType.source:
            if (leadSource === 'Unknown') {
                break;
            }
            setState(next(nodes, state));
            break;

        case FlowNodeType.snooze:
            if (!snooze) {
                setState(next(nodes, state));
                break;
            }

            if (state.context.endSnooze === undefined) {
                updateContext({
                    endSnooze:
                        state.context.endSnooze ?? snooze.state === 'waking',
                });
            }
            break;

        case FlowNodeType.linkPatient:
            if (!dentallyActive || dentallyLinked === 'linked') {
                setState(next(nodes, state));
            }
            break;
    }

    const transProps = {
        key: node.key + '-transition',
        dir,
    };

    let step = null;
    switch (node.type) {
        case FlowNodeType.schedule:
            step = (
                <Transition {...transProps}>
                    <ScheduleStep
                        type={node.type}
                        title={node.title}
                        initial={state.context}
                        label=""
                        onChange={updateContext}
                        defaultDays={node.defaultDays}
                        {...commonProps}
                    />
                </Transition>
            );
            break;
        case FlowNodeType.choices:
            step = (
                <Transition {...transProps}>
                    <ChoicesStep
                        type={node.type}
                        {...commonProps}
                        initial={{
                            value: node.mapping && state.context[node.mapping],
                        }}
                        title={node.title}
                        onChange={updateContext}
                        choices={node.choices}
                    />
                </Transition>
            );
            break;
        case FlowNodeType.note:
            step = (
                <Transition {...transProps}>
                    <NoteStep
                        type={node.type}
                        {...commonProps}
                        initial={{ note: state.context.note ?? '' }}
                        title={node.title}
                        onChange={updateContext}
                        subtitle={node.subtitle}
                        placeholder={node.placeholder}
                    />
                </Transition>
            );
            break;
        case FlowNodeType.confirm:
            step = (
                <Transition {...transProps}>
                    <ConfirmationStep
                        type={node.type}
                        title={node.title}
                        stage={stage}
                        {...commonProps}
                        context={context(state)}
                        onChange={() => null}
                        snooze={snooze}
                        delay={delay}
                    />
                </Transition>
            );
            break;
        case FlowNodeType.source:
            step = (
                <Transition {...transProps}>
                    <SourceStep
                        type={node.type}
                        title={node.title}
                        sources={leadSources}
                        initial={{
                            source: state.context.source || leadSource,
                        }}
                        {...commonProps}
                        onChange={updateContext}
                    />
                </Transition>
            );
            break;
        case FlowNodeType.snooze:
            if (!snooze) {
                console.warn(
                    'End snooze step should be skipped if snooze is not defined',
                );
                break;
            }

            step = (
                <Transition {...transProps}>
                    <EndSnoozeStep
                        type={node.type}
                        title={node.title}
                        snooze={snooze}
                        initial={{
                            endSnooze: state.context.endSnooze ?? false,
                        }}
                        {...commonProps}
                        onChange={updateContext}
                    />
                </Transition>
            );
            break;

        case FlowNodeType.linkPatient:
            step = (
                <Transition {...transProps}>
                    <LinkPatientStep
                        type={node.type}
                        title={node.title}
                        onChange={updateContext}
                        patient={patient}
                        {...commonProps}/>
                </Transition>
            );
            break;
        default:
            return null;
    }

    return step;
};

const slideIn = keyframes`
    from {
        transform: translateX(100%);
        opacity: 0;
    }

    to {
        transform: translateX(0%);
        opacity: 1;
    }
`;

const slideOut = keyframes`
    from {
        transform: translateX(0%);
        opacity: 0;
    }

    to {
        transform: translateX(100%);
        opacity: 1;
    }
`;

interface TransProps {
    dir: TransitionDirection;
    children?: JSX.Element | JSX.Element[];
}

const Transition = styled.div<TransProps>`
    ${({ dir }) =>
        dir !== TransitionDirection.NONE &&
        css`
            animation-duration: 0.25s;
            animation-name: ${dir === TransitionDirection.NEXT
                ? slideIn
                : slideOut};
        `}
`;
