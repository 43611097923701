import { Controller, useForm } from 'react-hook-form';
import React, { useState } from 'react';
import { Value } from '$state/types';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { useClickOutside } from '@/utils/hooks';
import CurrencyInput from 'react-currency-input-field';

interface FormProps {
    value: Value;
    currency: string;
    onSave?: (data: Value) => void;
    onCancel?: () => void;
    containerRef?: React.RefObject<HTMLElement>;
}

export const EditValueForm = ({
    value,
    currency,
    onSave,
    onCancel,
    containerRef,
}: FormProps) => {
    const internalRef = React.useRef<HTMLElement>(null);

    const {
        formState: { isDirty },
        ...form
    } = useForm<Value>({
        mode: 'onChange',
        reValidateMode: 'onChange',
        defaultValues: {
            proposed: value.proposed,
            accepted: value.accepted,
        },
    });

    const [confirming, setConfirming] = useState(false);

    const focusInput = (fieldName: 'proposed' | 'accepted') => {
        form.setFocus(fieldName);
    };

    const handleSave = (data: Value) => {
        if (!isDirty) {
            return;
        }

        onSave?.({
            paid: value.paid,
            proposed: parseFloat(data.proposed).toFixed(2),
            accepted: parseFloat(data.accepted).toFixed(2),
        });
    };

    const cancel = () => {
        if (!isDirty) {
            onCancel?.();
            return;
        }

        if (confirming) {
            onCancel?.();
            setConfirming(false);
            return;
        }

        setConfirming(true);
    };

    useClickOutside<HTMLElement>({
        ref: containerRef ?? internalRef,
        fn: (e) => {
            e.preventDefault();
            e.stopImmediatePropagation();
            cancel();
        },
    });

    return (
        <form onSubmit={form.handleSubmit(handleSave)}>
            <Container>
                <Heading>Edit value</Heading>
                <Description>
                    Modify the patient's value by adjusting the proposed and
                    accepted values below
                </Description>
                <InputWrapper>
                    <Label key="proposed-label" htmlFor="proposed">
                        Proposed value
                    </Label>
                    <Controller
                        key="preposed"
                        name="proposed"
                        control={form.control}
                        render={({ field, formState }) => {
                            return (
                                <FieldWrapper>
                                    <LabelWrapper>
                                        <InputLabel
                                            onClick={() =>
                                                focusInput('proposed')
                                            }
                                        >
                                            {currency}
                                        </InputLabel>
                                        <CurrencyInput
                                            customInput={Input}
                                            required
                                            value={field.value}
                                            data-cy={field.name}
                                            disabled={field.disabled}
                                            onBlur={field.onBlur}
                                            name={field.name}
                                            placeholder="Proposed value"
                                            onValueChange={(
                                                _value,
                                                _name,
                                                values,
                                            ) => {
                                                field.onChange(values?.value);
                                            }}
                                        />
                                    </LabelWrapper>
                                    {formState.errors?.proposed && (
                                        <Message>
                                            {formState.errors?.proposed.message}
                                        </Message>
                                    )}
                                </FieldWrapper>
                            );
                        }}
                    />
                </InputWrapper>

                <InputWrapper>
                    <Label key="accepted-label" htmlFor="accepted">
                        Accepted value
                    </Label>
                    <Controller
                        key="accepted"
                        name="accepted"
                        control={form.control}
                        render={({ field, formState }) => {
                            return (
                                <FieldWrapper>
                                    <LabelWrapper>
                                        <InputLabel
                                            onClick={() =>
                                                focusInput('accepted')
                                            }
                                        >
                                            {currency}
                                        </InputLabel>
                                        <CurrencyInput
                                            customInput={Input}
                                            required
                                            value={field.value}
                                            data-cy={field.name}
                                            disabled={field.disabled}
                                            onBlur={field.onBlur}
                                            name={field.name}
                                            placeholder="Accepted value"
                                            onValueChange={(
                                                _value,
                                                _name,
                                                values,
                                            ) => {
                                                field.onChange(values?.value);
                                            }}
                                        />
                                    </LabelWrapper>
                                    {formState.errors?.accepted && (
                                        <Message>
                                            {formState.errors?.accepted.message}
                                        </Message>
                                    )}
                                </FieldWrapper>
                            );
                        }}
                    />
                </InputWrapper>
            </Container>
            <Footer>
                <Button
                    hidden={!isDirty}
                    id="patient-edit-value-form-save"
                    data-cy="patient-edit-value-form-save"
                >
                    Save
                </Button>
                <CancelButton
                    onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        cancel();
                    }}
                    id="patient-edit-value-form-cancel"
                    data-cy="patient-edit-value-form-cancel"
                >
                    {confirming ? 'Are you sure?' : 'Cancel'}
                </CancelButton>
            </Footer>
        </form>
    );
};

const Container = styled.div`
    ${mix.padding({ padding: 3 })};
    ${mix.gap({ size: 2 })};
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid var(--gray-200);
    color: var(--gray-600);
`;

const Footer = styled.div`
    ${mix.padding({ padding: [1.5, 3] })};
    display: flex;
    justify-content: space-between;
`;

type ButtonProps = {
    hidden?: boolean;
};

const Button = styled.button<ButtonProps>`
    ${mix.type({ level: 'body2', bold: true })};
    ${mix.padding({ padding: [1, 2] })};
    background: var(--primary-500);
    border: 1px solid var(--primary-500);
    color: white;
    border-radius: 4px;
    min-width: ${mix.unit({ size: 15 })};
    min-height: ${mix.unit({ size: 4.5 })};
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;

    &:hover {
        cursor: pointer;
    }

    ${({ hidden }) => {
        return (
            hidden &&
            css`
                opacity: 0.5;

                &:hover {
                    cursor: default;
                }
            `
        );
    }}
`;

const CancelButton = styled(Button)`
    color: var(--gray-500);
    background: transparent;
    border-color: var(--gray-200);
`;

const Heading = styled.div`
    ${mix.type({ level: 'body1', bold: true })};
`;

const Description = styled.div`
    ${mix.type({ level: 'body2' })};
`;

const InputWrapper = styled.div`
    ${mix.gap({ size: 1 })}
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
`;

const Label = styled.label`
    position: relative;
    ${mix.type({ level: 'body2', bold: true })}
    font-size: 14px;
    color: var(--gray-600);
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 0.5 })};
    min-width: 100px;
`;

const Message = styled.span`
    ${mix.type({ level: 'body2', bold: true })}
    font-size: 14px;
    color: red;
`;

const FieldWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    ${mix.gap({ size: 0 })};
    width: 100%;
`;

const LabelWrapper = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
`;

const InputLabel = styled.label`
    position: absolute;
    left: 0;
    color: hsla(218, 15%, 65%, 0.8);
    flex: 1 0 auto;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: 400;
    margin: 0;
    border-right: 1px solid var(--gray-300);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    ${mix.padding({ padding: 2 })};
`;

const Input = styled.input`
    ${mix.padding({ padding: [1.5, 1.5, 1.5, 6] })};
    border: 1px solid var(--gray-300);
    border-radius: 4px;
    width: 100%;
`;
