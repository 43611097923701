import { Icon } from '$ui/Flo/Icon';
import { Monogram } from '$ui/Flo/Monogram';
import { Circular } from '$ui/Flo/Progress';
import { Shadow } from '$ui/Flo/Shadow';
import { mix } from '$ui/Flo/util';
import { dates, lang, num } from '$utils';
import React from 'react';
import styled from 'styled-components';

// https://developer.dentally.co/#the-patient-object
export interface DentallyResult {
    id: number; // dentally_id
    first_name: string;
    last_name: string;
    image_url?: string;
    email_address: string | null;
    phone: string | null;
    date_of_birth: string;
}

export interface ResultItemProps {
    patient?: DentallyResult;
    score?: number; // matching score
    query?: string;
    loading?: boolean; // when loading patient is empty | undefined
    onLink?: (dentally_id: string) => void;
    onView?: (dentally_id: string) => void;
    bold?: boolean; // whether the bold function should be applied
    linking?: boolean;
}

export const ResultItem = (props: ResultItemProps) => {
    const {
        patient,
        score,
        onLink,
        onView,
        loading = false,
        linking = false,
    } = props;

    const origin = { name: '', dob: '', email: '', phone: '' };

    if (!loading && patient) {
        origin.name = `${patient.first_name} ${patient.last_name}`;
        origin.dob = dates.toDate(patient.date_of_birth);
        origin.email = patient.email_address ?? '';
        origin.phone = patient.phone ?? '';
    }

    const getValue = (key: keyof DentallyResult) => {
        if (!loading && patient) {
            return patient[key as keyof DentallyResult];
        }

        if (loading) {
            return <LoadingBar />;
        }

        return '';
    };

    return (
        <Wrapper
            $loading={loading}
            data-testid={`result-${patient?.id ?? 'loading'}`}
        >
            <PatientBlock>
                <Row>
                    <PatientImage
                        loading={loading}
                        patient={patient}
                        score={score}
                    />
                    <Column
                        style={{
                            justifyContent: 'center',
                        }}
                    >
                        <Name>
                            {loading ? (
                                <LoadingBar />
                            ) : (
                                getValue('first_name') +
                                ' ' +
                                getValue('last_name')
                            )}
                        </Name>
                        <Text>{getValue('date_of_birth')}</Text>
                    </Column>
                </Row>
            </PatientBlock>
            <PatientContact>
                <Column>
                    <Text>
                        <Icon icon="Mail" color="gray-600" opacity={1} />
                        {getValue('email_address')}
                    </Text>
                    <Text>
                        <Icon icon="PhoneCall" color="gray-600" opacity={1} />
                        {getValue('phone')}
                    </Text>
                </Column>
            </PatientContact>
            {!loading && (
                <Buttons>
                    <Button
                        onClick={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            onLink && onLink(patient?.id.toString() ?? '');
                        }}
                        $linking={linking}
                        data-testid={`dentally-link-patient`}
                    >
                        <Icon icon="Link" color="white" opacity={1} /> Link
                    </Button>

                    <ViewButton
                        onClick={(event) => {
                            event.stopPropagation();
                            event.nativeEvent.stopImmediatePropagation();
                            onView && onView(patient?.id.toString() ?? '');
                        }}
                    >
                        <Icon
                            icon="ExternalLink"
                            color="primary-500"
                            opacity={1}
                        />{' '}
                        View
                    </ViewButton>
                </Buttons>
            )}
        </Wrapper>
    );
};

const LoadingText = styled(Shadow)`
    ${mix.margin({ margin: [0.25, 0.25, 0.25, 1] })}
`;

const LoadingBar = () => {
    return (
        <LoadingText
            hue="grey"
            shade="8"
            rounded
            height={2}
            width={num.randomInt(50, 100)}
        />
    );
};

const Wrapper = styled.div<{ $loading: boolean }>`
    ${mix.type({ level: 'body2' })};
    display: grid;
    ${({ $loading }) =>
        $loading
            ? 'grid-template-columns: 1fr'
            : 'grid-template-columns: 3fr 1fr'};
    width: 100%;
    border-bottom: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    ${mix.gap({ size: 1.5 })}
    padding-bottom: ${mix.unit({ size: 1.5 })};
`;

const PatientBlock = styled.div`
    grid-area: 1 / 1 / 2 / 2;
    ${mix.gap({ size: 1 })}
`;

const PatientContact = styled.div`
    grid-area: 2 / 1 / 3 / 2;
    ${mix.gap({ size: 1 })}
`;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    ${mix.gap({ size: 1.5 })}
    width: 100%;
    height: 100%;
`;

const Column = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const Buttons = styled.div`
    grid-area: 1 / 2 / 3 / 3;
    ${mix.gap({ size: 0.5 })}
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: end;

    & > div {
        min-width: ${mix.unit({ size: 12 })};
    }
`;

const Name = styled.div`
    font-family: Roboto, sans-serif;
    color: var(--gray-600);
    font-size: 16px;
    font-weight: 500;
`;

const Text = styled.div`
    display: flex;
    ${mix.gap({ size: 1 })}
    align-items: center;
    font-family: Roboto, sans-serif;
    color: var(--gray-600);
    font-size: 14px;
    font-weight: 400;
`;

export const PatientImage = (props: {
    patient?: DentallyResult;
    score?: number;
    loading?: boolean;
}) => {
    const { patient, score, loading } = props;
    const IMAGE_RADIUS = 4;
    return (
        <IndicatorWrapper radius={IMAGE_RADIUS * 2}>
            <PatientAvatarWrapper>
                <Avatar loading={loading} patient={patient} />
            </PatientAvatarWrapper>
            {!loading && score && score > 0 && (
                <Indicator>
                    <Circular
                        radius={IMAGE_RADIUS}
                        thickness={0.25}
                        rates={{
                            success: score,
                        }}
                        hues={{
                            track: 'none',
                            success: 'primary-500',
                        }}
                        rounded
                    />
                </Indicator>
            )}
        </IndicatorWrapper>
    );
};

export const Avatar: React.FC<{
    patient?: DentallyResult;
    loading?: boolean;
}> = (props) => {
    const { patient, loading } = props;

    if (loading) {
        return <Monogram name="" color="gray-300" />;
    }

    if (patient && patient.image_url && !lang.isEmpty(patient.image_url)) {
        return <PatientAvatar src={patient.image_url} />;
    }

    if (patient && !patient.image_url) {
        const name = patient
            ? patient.first_name + ' ' + patient.last_name
            : 'Not Available';

        return (
            <Monogram name={name} color="gray-200" fontColor="primary-500" />
        );
    }
    // must be loading
    return <Monogram name="" color="gray-300" />;
};

const IndicatorWrapper = styled.div<{ radius: number }>`
    ${({ radius }) => mix.sq({ size: radius })}
    position: relative;
    display: flex;
    margin: auto;
`;

export const PatientAvatarWrapper = styled.div`
    height: 100%;
    width: inherit;
    border-radius: 50%;
    position: relative;
    background: transparent;
    transform: scale(0.85);
    overflow: hidden;
`;

const PatientAvatar = styled.img`
    display: inline;
    height: auto;
    width: 100%;
`;

const Indicator = styled.svg`
    width: 100%;
    height: 100%;
    position: absolute;
`;

const Button = styled.button<{ $linking?: boolean }>`
    ${mix.padding({ padding: [1, 2, 1, 2] })};
    ${mix.height({ size: 4.5 })};
    ${mix.width({ size: 12.5 })};

    outline: none;
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 300ms ease;
    pointer-events: auto;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: white;
    gap: ${mix.unit({ size: 1 })};
    font-family: 'Roboto', sans-serif;
    cursor: pointer;

    ${({ $linking }) =>
        $linking
            ? 'background-color: var(--primary-200)'
            : 'background-color: var(--primary-500)'};
`;

const ViewButton = styled(Button)`
    border: 1px solid var(--primary-100);
    background-color: white;
    color: var(--primary-500);
`;
