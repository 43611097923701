import * as dentally from '$state/concerns/dentally/patients';
import * as React from 'react';
import { FinancialBar, FinancialBreakdown } from '$ui/Dentally';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';

export interface Financial {
    financials?: dentally.PatientFinancials;
    children?: React.ReactNode;
}

export const FinancialSummary = (props: Financial) => {
    const { financials } = props;
    if (!financials) {
        return <FinancialBar loading />;
    }
    const [open, setOpen] = React.useState(false);

    const { total } = financials;

    const handleOpen = () => {
        if (Number(total.paid) === 0 && Number(total.unpaid) === 0) {
            return;
        }

        setOpen(true);
    };

    const handleClose = () => {
        if (open) setOpen(false);
    };

    return (
        <div
            onMouseEnter={() => handleOpen()}
            onMouseLeave={() => handleClose()}
        >
            {props.children}

            {open && (
                <FinancialWrapper>
                    <FinancialBreakdown financial={financials} />
                </FinancialWrapper>
            )}
        </div>
    );
};

const FinancialWrapper = styled.div`
    display: block;
    position: absolute;
    height: auto;
    ${mix.padding({ padding: [2] })}
    z-index: 1001;
    left: 0;
    right: 0;
    transform: translateY(-12px);

    & > div {
        position: relative;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: white;
    }
`;
