import { RootState } from '$state/store';
import { LoadedReportState, ReportState } from '$state/types';
import { createSelector } from '@reduxjs/toolkit';

export type ValueType = 'estimated' | 'proposed' | 'accepted' | 'lost';

export type AggregateValue = {
    type: ValueType;
    value: string;
    difference?: {
        value: string;
        direction: 'positive' | 'negative' | 'neutral';
    };
};

export type DeltaDirection = 'positive' | 'negative' | 'neutral';

type Metric = {
    loading: boolean;
    value: string;
    delta: {
        direction: DeltaDirection;
        change?: string;
    };
    aggregateValue?: AggregateValue;
};

const isReportLoaded = <T>(
    report: ReportState<T>,
): report is LoadedReportState<T> => {
    return report.status === 'loaded';
};

const direction = {
    consult_rate: 'direct',
    won_rate: 'direct',
    new_leads: 'direct',
    consults_booked: 'direct',
    failed_to_attend: 'inverse',
    tx_plan_consults: 'direct',
    won: 'direct',
    not_interested: 'inverse',
    maybe_future: 'inverse',
    blocked: 'inverse',
    lost: 'inverse',
    losses: 'inverse',
    leads_received: 'direct',
    gone_ahead: 'direct',
    email_sent: 'direct',
    email_received: 'direct',
    sms_sent: 'direct',
    sms_received: 'direct',
    avg_initial_contact_secs: 'inverse',
};

const calculateDirection = (
    metric: string,
    difference: number,
): DeltaDirection => {
    if (difference === 0) {
        return 'neutral';
    }

    if (direction[metric] === 'inverse') {
        return difference > 0 ? 'negative' : 'positive';
    }

    return difference > 0 ? 'positive' : 'negative';
};

const formatChange = (difference: number): string | undefined => {
    if (difference === 0) {
        return undefined;
    }

    const sign = difference > 0 ? '+' : '';
    const change = difference.toLocaleString(undefined, {
        maximumFractionDigits: 2,
    });
    return `${sign}${change}%`;
};

const formatRate = (rate: number): string =>
    (100 * rate).toLocaleString(undefined, {
        maximumFractionDigits: 2,
    });

export const selectMetric = createSelector(
    [
        (state: RootState) => state.reporting,
        (_, report: string) => report,
        (_, __, metric: string) => metric,
    ],
    (reporting, report, metric): Metric => {
        if (!isReportLoaded(reporting[report])) {
            return {
                loading: true,
                value: '0',
                delta: {
                    direction: 'neutral' as DeltaDirection,
                },
            };
        }

        const { current, previous } = reporting[report].metrics;

        const difference = current[metric] - previous[metric];
        const direction = calculateDirection(metric, difference);

        if (/_rate$/.test(metric)) {
            return {
                loading: false,
                value: formatRate(Number(current[metric])) + '%',
                delta: {
                    direction,
                    change: formatChange(100 * difference),
                },
            };
        }

        const differencePercentage = (difference / previous[metric]) * 100;

        return {
            loading: false,
            value: current[metric].toString(),
            delta: {
                direction,
                change: formatChange(
                    Number.isNaN(differencePercentage)
                        ? 0
                        : differencePercentage,
                ),
            },
        };
    },
);
