interface FormatArgs {
    value: number | string;
    locale?: string;
    currency?: string;
    compact?: boolean;
    fractionDigits?: number;
    maximumSignificantDigits?: number;
    maximumFractionDigits?: number;
}

export const format = ({
    value,
    locale,
    currency,
    fractionDigits,
    compact,
    maximumSignificantDigits,
    maximumFractionDigits,
}: FormatArgs): string => {
    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    return new Intl.NumberFormat(locale, {
        style: 'currency',
        currency: currency ?? 'GBP',
        minimumFractionDigits: fractionDigits ?? 2,
        notation: compact ? 'compact' : 'standard',
        maximumFractionDigits,
        maximumSignificantDigits,
    }).format(value);
};

export const formatWithoutCurrency = ({
    value,
    fractionDigits,
    compact,
    maximumFractionDigits,
}: FormatArgs): string => {
    if (typeof value === 'string') {
        value = parseFloat(value);
    }

    return new Intl.NumberFormat('en-GB', {
        minimumFractionDigits: fractionDigits ?? 2,
        maximumFractionDigits: maximumFractionDigits ?? 2,
        notation: compact ? 'compact' : 'standard',
    }).format(value);
};
