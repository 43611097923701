
import { IntegrationsBar } from './IntegrationsBar';
import * as React from 'react';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { IconName } from '$ui/Flo/Icon';

interface Props {
    id: string;
    position: 'left' | 'right';
    onSelect?: (integration: string) => void;
    selected?: string;
    integrations: {
        [key: string]: {
            name: string;
            icon: IconName;
            status: 'linked' | 'unlinked';
        };
    };
}

export const Integrations = (props: Props) => {
    const { position = 'left', integrations } = props;
    return (
        <IntegrationBarWrapper>
            <IntegrationsBar
                position={position}
                integrations={integrations}
                onClick={props.onSelect}
            />
        </IntegrationBarWrapper>
    );
};

const IntegrationBarWrapper = styled.div`
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    ${mix.padding({ padding: [1] })};
    ${mix.bg({ hue: 'grey', shade: '10', alpha: 0.5 })}
`;
