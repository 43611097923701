import { mix } from '$ui/Flo/util';
import { Form } from '$ui/Login/LegacyMode';
import { Quote } from '$ui/Login';
import React, { SVGProps } from 'react';
import styled from 'styled-components';
import LogoOSvg from '$resources/logo-o.svg';
import * as legacy from '@/auth/modes/legacy';
import * as concurrent from '@/auth/modes/concurrent';
import * as sso from '@/auth/modes/sso';
import { usePageTitle } from '@/utils/hooks';
import { allowedRedirectUrls, useBaseURL, useUIBaseURL } from '@/config/react';
import { Credentials } from '@/auth/modes/types';
import {
    useAuthenticating,
    useCredentialsError,
    useInviteError,
    useInviteSending,
    useInviting,
    useSSOError,
} from '@/auth/react';
import { useAuthMode } from '@/config/react';
import { Heading as HeadingFlo } from '$ui/Flo/Heading';
import { MigrationForm } from '$ui/Login/ConcurrentMode/MigrationForm';
import { Separator, SSOLogin } from '$ui/Login/ConcurrentMode';
import LogoSvg from '$resources/logo.svg';
import AgilioSoftwareSvg from '$resources/agilio-software.svg';
import AgilioGroupSvg from '$resources/agilio-group-white.svg';
import SSOLoginBackgroundSvg from '$resources/sso-login-background.svg';

export const Login = () => {
    const authMode = useAuthMode();
    usePageTitle('Login');

    switch (authMode) {
        case 'legacy':
            return <LegacyMode />;

        case 'concurrent':
            return <ConcurrentMode />;

        case 'sso':
            return <SSOMode />;

        default:
            return null;
    }
};

const LegacyMode = () => {
    const error = useCredentialsError();
    const allowedUrls = allowedRedirectUrls();

    const onLogin = (details: Credentials) => {
        legacy.actor.send({
            type: 'authenticate',
            email: details.email,
            password: details.password,
            allowedRedirectUrls: allowedUrls,
        });
    };

    return (
        <Container>
            <Left>
                <FormContainer>
                    <OShape />
                    <Heading>
                        Login to <span>Leadflo</span>
                    </Heading>
                    <Form onSubmit={onLogin} error={error} />
                </FormContainer>
            </Left>
            <Right>
                <QuoteContainer>
                    <Quote />
                </QuoteContainer>
            </Right>
        </Container>
    );
};

const ConcurrentMode = () => {
    const error = useCredentialsError();
    const inviteError = useInviteError();
    const ssoError = useSSOError();
    const allowedUrls = allowedRedirectUrls();
    const inviting = useInviting();
    const inviteSending = useInviteSending();
    const isAuthenticating = useAuthenticating();
    const uiBaseUrl = useUIBaseURL();
    const apiBaseUrl = useBaseURL();

    const onLogin = (details: Credentials) => {
        concurrent.loginWithCredentials(details, allowedUrls);
    };

    const onSSO = () => {
        if (!apiBaseUrl || !uiBaseUrl) {
            throw new Error(
                'Config must be injected by this point. This ' +
                    'is a serious programming error and the application is ' +
                    'in an invalid state.',
            );
        }

        concurrent.initiateSSO({ apiBaseUrl });
    };

    const onResendInvite = () => {
        concurrent.invite();
    };

    const errorMessage = error || inviteError;

    return (
        <Container>
            <Left>
                <FormContainer>
                    <HeadingConccurent>
                        Login to <span>Leadflo</span>
                    </HeadingConccurent>
                    {!inviting && (
                        <>
                            <SSOLogin
                                onClick={onSSO}
                                loading={isAuthenticating}
                                error={ssoError}
                            />
                            <Separator />
                            <HeadingFlo level="h4" align="left" vSpace={2}>
                                Login with email and password
                            </HeadingFlo>
                            <TextContainer>
                                Upon login, you will be sent an invite via email and
                                asked to register a new AgilioID or, if you have one,
                                link your existing Agilio ID.
                            </TextContainer>
                        </>
                    )}
                    <MigrationForm
                        onLogin={onLogin}
                        onResendInvite={onResendInvite}
                        error={errorMessage}
                        inviting={inviting}
                        inviteSending={inviteSending}
                        isAuthenticating={isAuthenticating}
                    />
                </FormContainer>
            </Left>
            <Right>
                <QuoteContainer>
                    <Quote />
                </QuoteContainer>
            </Right>
        </Container>
    );
};

const SSOMode = () => {
    const error = useSSOError();
    const authenticating = useAuthenticating();
    const apiBaseUrl = useBaseURL();
    const uiBaseUrl = useUIBaseURL();

    const onLogin = () => {
        if (!uiBaseUrl || !apiBaseUrl) {
            throw new Error(
                'Config must be injected by this point. This ' +
                    'is a serious programming error and the application is ' +
                    'in an invalid state.',
            );
        }

        sso.initiateSSO({ apiBaseUrl });
    };

    return (
        <Container>
            <LeftSSO>
                {error && <ErrorMessageHolder><ErrorMessage>{error}</ErrorMessage></ErrorMessageHolder>}
                <FormContainerSSO>
                    <Logo />
                    <HeadingSSO>
                        Login to Leadflo
                    </HeadingSSO>
                    <SSOLogin
                        onClick={onLogin}
                        loading={authenticating}
                    />
                </FormContainerSSO>
                <AgilioSoftwareHolder>
                    <AgilioSoftware />
                </AgilioSoftwareHolder>
            </LeftSSO>
            <RightSSO>
               <RightBackground viewBox={'0 0 1000 1000'} />
               <QuoteLine>Communicate.</QuoteLine>
               <QuoteLine>Automate.</QuoteLine>
               <QuoteLine>Convert.</QuoteLine>
                <AgilioGroupHolder>
                    <AgilioGroup />
                </AgilioGroupHolder>
            </RightSSO>
        </Container>
    );
};

const OShape = styled(LogoOSvg)`
    ${mix.width({ size: 6 })};
    ${mix.fill({ hue: 'primary', shade: '6' })};
    height: auto;
`;

const Container = styled.div`
    display: flex;
    height: 100vh;

    @media (max-width: 1200px) {
        flex-direction: column;
    }
`;

const Left = styled.div`
    ${mix.bg({ hue: 'grey', shade: '8' })};
    flex: 0 0 38%;
    display: flex;
    flex-direction: column;
    justify-content: center;
`;

const LeftSSO = styled(Left)`
    background-color: var(--primary-050);
    flex: 0 0 50%;

    @media (max-width: 1200px) {
        padding-top: ${mix.unit({ size: 12.5 })};
    }
`;

const FormContainer = styled.div`
    ${mix.bg({ hue: 'white' })};
    ${mix.padding({ padding: 4 })};
`;

const FormContainerSSO = styled(FormContainer)`
    display: flex;
    flex-direction: column;
    text-align: center;
    gap: ${mix.unit({ size: 2.5 })};
    background-color: var(--primary-050);
`;

const Heading = styled.div`
    ${mix.type({ level: 'h2' })};
    ${mix.color({ profile: 'body' })};
    ${mix.margin({ margin: [4, 0] })};
    font-weight: bold;
    line-height: 1;

    span {
        color: ${mix.palette({ hue: 'primary', shade: '6' })};
    }
`;

const HeadingConccurent = styled(Heading)`
    text-align: center;
`;

const HeadingSSO = styled(Heading)`
    font-weight: 600;
    font-size: ${mix.unit({ size: 6 })};
    font-family: Roboto, sans-serif;
    margin: 0;
    color: var(--gray-900);
`;

const Right = styled.div`
    background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='29' height='50.115' patternTransform='scale(1) rotate(20)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(225, 48%, 13%, 1)'/><path d='M14.498 16.858L0 8.488.002-8.257l14.5-8.374L29-8.26l-.002 16.745zm0 50.06L0 58.548l.002-16.745 14.5-8.373L29 41.8l-.002 16.744zM28.996 41.8l-14.498-8.37.002-16.744L29 8.312l14.498 8.37-.002 16.745zm-29 0l-14.498-8.37.002-16.744L0 8.312l14.498 8.37-.002 16.745z'  stroke-width='1' stroke='hsla(225, 36%, 22%, 1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>");
    background-color: #111931;
    flex: 1 0 auto;
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const RightSSO = styled(Right)`
    background-image: none;
    background-color: #101828;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: ${mix.unit({ size: 6 })};
`;

const RightBackground = styled(SSOLoginBackgroundSvg)<SVGProps<SVGElement>>`
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.16;
`;

const QuoteContainer = styled.div`
    position: absolute;
    right: ${mix.unit({ size: 6 })};
    bottom: ${mix.unit({ size: 6 })};
`;

const TextContainer = styled.div`
    ${mix.type({ level: 'body1' })};
    margin-bottom: ${mix.unit({ size: 2 })};
`;

const Logo = styled(LogoSvg)`
    height: ${mix.unit({ size: 5 })};
`;

const AgilioSoftwareHolder = styled.div`
    position: absolute;
    bottom: ${mix.unit({ size: 6 })};
    display: flex;
    justify-content: center;
    width: 50%;
`;

const AgilioSoftware = styled(AgilioSoftwareSvg)`
    color: var(--gray-500);
`;

const AgilioGroupHolder = styled.div`
    position: absolute;
    bottom: ${mix.unit({ size: 6 })};
    right: ${mix.unit({ size: 6 })};
    display: flex;
    justify-content: center;
`;

const AgilioGroup = styled(AgilioGroupSvg)`
    color: white;
`;

const ErrorMessageHolder = styled.div`
    display: flex;
    justify-content: center;
    width: 50%;
    position: absolute;
    top: ${mix.unit({ size: 6 })};

    @media (max-width: 1200px) {
        left: ${mix.unit({ size: 6 })};
        right: ${mix.unit({ size: 6 })};
        width: auto;
    }
`;

const ErrorMessage = styled.div`
    ${mix.padding({ padding: [1.5,2,1.5,2] })};
    border-radius: ${mix.unit({ size: 0.5 })};
    background-color: var(--error-100);
    color: var(--error-700);
    height: ${mix.unit({ size: 5.5 })};
    width: ${mix.unit({ size: 70 })};
    font-family: Roboto;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;

    @media (max-width: 1200px) {
        width: 100%;
    }
`;

const QuoteLine = styled.h2`
    color: white;
    font-family: Roboto, sans-serif;
    font-size: ${mix.unit({ size: 10 })};
    font-weight: 500;
    margin: 0;
`;
