import * as React from 'react';
import { Align } from '$ui/Flo/types';
import { AggregateValue, DeltaDirection } from '$state/queries/reporting';
import styled, { css } from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { Tooltip } from '$ui/Flo/Tooltip';
import { Shadow } from '$ui/Flo/Shadow';
import { dates } from '$utils';
import { Value as AggregatedValue } from './Value';

export type Props = {
    id: string | null; // The ID of the report this metric links to
    onClick?: () => void;
    value: string;
    label: string;
    delta?: {
        change?: string;
        direction: DeltaDirection;
    };
    breakdown?: Array<{
        value: string;
        label: string;
        uncounted?: boolean;
    }>;
    tooltip?: string;
    tooltipAlign?: Align;
    loading?: boolean;
    fill?: boolean;
    unit?: 'absolute' | 'interval';
    currency: string;
    aggregateValue?: AggregateValue;
};

/**
 * A metric is a number with an optional 'delta' (difference from previous
 * period) and a label, along with an optional tooltip and optional link to
 * a report.
 *
 * This component supports:
 *
 * - A loading state
 * - Displaying the metric value and label
 * - Displaying the difference as a percentage
 * - Coloring the percentage based on the direction of the change and type of
 *   metric
 * - Displaying a tooltip on hover
 * - Linking to a report breakdown
 * - Displaying an indicator that indicates a link to a report breakdown
 * - Support a mini-breakdown into the reasons for the metric value (e.g maybe
 *   future, not interested, blocked)
 */
export const Metric = (props: Props) => {
    const {
        id,
        loading,
        value,
        label,
        delta,
        tooltip,
        tooltipAlign = 'center',
        breakdown = [],
        onClick = () => null,
        fill = false,
        unit = 'absolute',
    } = props;

    const [tooltipOpen, setTooltipOpen] = React.useState(false);

    const tooltipIndicator = tooltip && (
        <TooltipIndicator
            onMouseOver={() => setTooltipOpen(true)}
            onMouseOut={() => setTooltipOpen(false)}
        >
            {tooltip && (
                <Tooltip
                    width={27.5}
                    open={tooltipOpen}
                    shade="1"
                    align={tooltipAlign}
                >
                    {tooltip}
                </Tooltip>
            )}
            <Icon icon="Info" opacity={1} size={2} />
        </TooltipIndicator>
    );

    const linkIndicator = id && (
        <LinkIndicator>
            <Icon icon="ArrowUpRight" opacity={0.9} size={2} clickable />
        </LinkIndicator>
    );

    if (loading) {
        return (
            <Container data-cy={label} onClick={onClick} id={id ?? undefined}>
                {tooltipIndicator}
                <LabelContainer>
                    <Label>
                        <LoadingShadow
                            shade="8"
                            width="14.875"
                            rounded
                            height={2.25}
                        />
                    </Label>
                    {linkIndicator}
                </LabelContainer>
                <Headline>
                    <Value>
                        <LoadingShadow
                            shade="8"
                            width="8.625"
                            rounded
                            height={5.25}
                        />
                    </Value>
                </Headline>
                {breakdown.length > 0 && (
                    <Breakdown>
                        {breakdown.map((_, index) => (
                            <BreakdownRow key={index}>
                                <BreakdownCell>
                                    <LoadingShadow
                                        shade="8"
                                        width="17.5"
                                        rounded
                                        height={1.75}
                                    />
                                </BreakdownCell>
                                <BreakdownValueCell>
                                    <LoadingShadow
                                        shade="8"
                                        width="2"
                                        rounded
                                        height={1.75}
                                    />
                                </BreakdownValueCell>
                            </BreakdownRow>
                        ))}
                    </Breakdown>
                )}
            </Container>
        );
    }

    const breakdownItems = breakdown.map((item, index) => (
        <BreakdownRow key={index} uncounted={item.uncounted}>
            <BreakdownCell>{item.label}</BreakdownCell>
            <BreakdownValueCell>
                {`${item.uncounted ? '+' : ''}${item.value}`}
            </BreakdownValueCell>
        </BreakdownRow>
    ));

    return (
        <Container
            data-cy={label}
            onClick={onClick}
            id={id ?? undefined}
            fill={fill}
        >
            {tooltipIndicator}
            <LabelContainer>
                <Label>{label}</Label>
                {linkIndicator}
            </LabelContainer>
            <Headline>
                <Value data-cy="value">
                    {unit === 'interval'
                        ? dates.duration(parseInt(value))
                        : value}
                </Value>
                {delta && delta.change && (
                    <Delta direction={delta.direction}>{delta.change}</Delta>
                )}
            </Headline>
            {breakdown.length > 0 && <Breakdown>{breakdownItems}</Breakdown>}
            {props.aggregateValue && (
                <div>
                    <AggregatedValue
                        value={props.aggregateValue}
                        currency={props.currency}
                    />
                </div>
            )}
        </Container>
    );
};

interface ContainerProps {
    id?: string;
    fill?: boolean;
}

// TODO: Refactor colour system
const Container = styled.div<ContainerProps>`
    ${mix.padding({ padding: 3 })};
    ${mix.gap({ size: 2 })};
    background: transparent;
    border: 1px solid var(--gray-200);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 201;
    height: ${({ fill }) => (fill ? '100%' : 'auto')};

    &:hover {
        ${({ id }) =>
            id &&
            css`
                cursor: pointer;
            `};
    }
`;

const LoadingShadow = styled(Shadow)`
    vertical-align: middle;
`;

const TooltipIndicator = styled.div`
    position: absolute;
    right: ${mix.unit({ size: 2 })};
    top: ${mix.unit({ size: 2 })};
    z-index: 202;
`;

const Headline = styled.div`
    display: flex;
    ${mix.gap({ size: 1 })};
`;

const Breakdown = styled.table`
    ${mix.type({ level: 'body2' })};
    color: #344054;
    border-collapse: collapse;
`;

const BreakdownRow = styled.tr<{ uncounted?: boolean }>`
    border-bottom: 1px solid rgba(0, 0, 0, 0.04);

    ${({ uncounted }) =>
        uncounted &&
        css`
            mix-blend-mode: color-burn;
            opacity: 0.625;
        `};

    :last-child {
        border-bottom: none;
    }
`;

const BreakdownCell = styled.td`
    ${mix.padding({ padding: [1, 0] })};
    vertical-align: middle;
`;

const BreakdownValueCell = styled(BreakdownCell)`
    text-align: right;
    font-weight: 600;
`;

const LabelContainer = styled.div`
    display: flex;
    align-items: center;
    ${mix.gap({ size: 1 })};
`;

// TODO: Refactor to colour / type system
const Value = styled.div`
    font-size: 36px;
    font-weight: 600;
    line-height: 1.4;
    font-family: 'Roboto', sans-serif;
    color: var(--gray-900);
`;

const Delta = styled.div<{ direction: DeltaDirection }>`
    ${mix.type({ level: 'body2' })};
    ${mix.padding({ padding: 0 })};
    ${mix.height({ size: 3 })};
    line-height: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    ${({ direction }) => {
        switch (direction) {
            case 'positive':
                return css`
                    color: ${mix.palette({ hue: 'green', shade: '5' })};
                `;

            case 'negative':
                return css`
                    color: ${mix.palette({ hue: 'red', shade: '5' })};
                `;

            default:
                return css`
                    color: ${mix.palette({ hue: 'grey', shade: '5' })};
                `;
        }
    }};
`;

// TODO: Refactor to colour
const Label = styled.div`
    ${mix.type({ level: 'body2' })};
    font-size: 16px;
    color: var(--gray-600);
    line-height: 2;
`;

const LinkIndicator = styled.div`
    ${mix.sq({ size: 1 })};
    display: flex;
    align-items: center;
    justify-content: center;
`;
