import React from 'react';
import { StepProps } from '../types';
import styled from 'styled-components';
import { mix } from '$ui/Flo/util';
import { Icon } from '$ui/Flo/Icon';
import { FlowNodeType } from '$types';
import { Patient } from '$state/types';
import { phone } from '$utils';
import { useDentallySuggestions } from '$ui/Actions/Flow/Steps/LinkPatientStep/hooks';
import { ResultItem } from '$ui/Dentally/V2';
import { useDebounce } from '$state';

interface LinkPatientValue {
    dentally?: {
        link: {
            patientId: string;
            dentallyId: number;
        };
    };
}

export interface Props extends StepProps<LinkPatientValue> {
    type: FlowNodeType.linkPatient;
    patient?: Patient;
}

export const LinkPatientStep = ({
    onBack,
    onNext,
    proceedable,
    patient,
    onChange,
}: Props) => {
    const defaultQuery =
        patient?.first_name +
        ' ' +
        patient?.last_name +
        ' ' +
        patient?.email +
        ' ' +
        phone.format(patient?.phone ?? '').replace(/\s/g, '');

    const [query, setQuery] = React.useState<string>(defaultQuery);
    const [linking, setLinking] = React.useState<number | undefined>(undefined);
    const { isFetched, isLoading, data } = useDentallySuggestions(query);

    const onLink = (patientId: string, dentallyId: number) => {
        if (linking && linking === dentallyId) {
            setLinking(undefined);
            onChange({
                dentally: undefined,
            });
            return;
        } else {
            setLinking(dentallyId);
            onChange({
                dentally: {
                    link: {
                        patientId,
                        dentallyId,
                    },
                },
            });
        }
    };

    const onView = (dentallyId: number) => {
        window.open(
            `https://app.dentally.co/patients/${dentallyId}/details`,
            '_blank',
        );
    };

    const onAdd = () => {
        console.log('ON ADD');
    };

    const onSearch = useDebounce(
        350,
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const query = e.target.value;

            setQuery(query.length > 0 ? query : defaultQuery);
        },
    );

    return (
        <Container data-cy="link-patient-step">
            <Header>
                Link {patient ? patient.first_name : 'patient'} to Dentally
            </Header>
            <Content>
                {isLoading && <ResultItem loading />}
                {isFetched && data?.length === 0 && (
                    <Text>
                        We couldn't find any potential matching patients. You
                        can try searching for them below using any of their
                        details or create a new one.
                    </Text>
                )}
                {isFetched && data && data.length > 0 && (
                    <Results>
                        {data?.map((dentallyPatient) => (
                            <ResultItem
                                patient={dentallyPatient}
                                score={
                                    dentallyPatient.confidence !== 0
                                        ? dentallyPatient.confidence
                                        : undefined
                                }
                                key={dentallyPatient.id}
                                onLink={(dentallyId) =>
                                    patient &&
                                    onLink(patient.id, parseInt(dentallyId))
                                }
                                onView={(dentallyId) =>
                                    onView(parseInt(dentallyId))
                                }
                                linking={dentallyPatient.id === linking}
                            />
                        ))}
                    </Results>
                )}
            </Content>
            <Search>
                <InputGroup>
                    <Input
                        data-testid="dentally-search-input"
                        placeholder={'Search for a patient in Dentally...'}
                        onChange={(e) => onSearch(e)}
                    />
                    <AddButton onClick={() => onAdd()}>
                        <Icon icon="Plus" color="primary-500" opacity={1} /> Add
                        New
                    </AddButton>
                </InputGroup>
            </Search>
            <Footer>
                <BackButton onClick={onBack}>
                    <Icon icon="ArrowLeft" color="gray-500" opacity={1} /> Back
                </BackButton>
                {linking ? (
                    <NextButton
                        disabled={!proceedable}
                        onClick={onNext}
                        data-cy="flow-step-next"
                    >
                        Next
                    </NextButton>
                ) : (
                    <Button
                        disabled={!proceedable}
                        onClick={onNext}
                        data-cy="flow-step-next"
                    >
                        Skip
                    </Button>
                )}
            </Footer>
        </Container>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    ${mix.height({ size: 9 })};
    display: flex;
    flex-direction: row;
    align-items: center;
    font-family: 'Inter', sans-serif;
    font-size: ${mix.unit({ size: 2.25 })};
    line-height: ${mix.unit({ size: 3 })};
    font-weight: 600;
    color: var(--gray-600);
    ${mix.padding({ padding: [0, 4] })};
    border-bottom: 1px solid var(--gray-200);
`;

const Content = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 4 })};
    ${mix.padding({ padding: [4] })};
`;

const Results = styled.div`
    display: flex;
    flex-direction: column;
    ${mix.gap({ size: 2 })};
    max-height: 400px;
    overflow-y: auto;

    & > div:last-child {
        padding-bottom: 0;
        border-bottom: 1px solid transparent;
    }
`;

const Footer = styled.div`
    ${mix.height({ size: 10.5 })};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    ${mix.padding({ padding: [4] })};
`;

const Text = styled.p`
    font-family: Roboto, sans-serif;
    font-size: 14px;
`;

const Button = styled.button`
    ${mix.padding({ padding: [1, 2, 1, 2] })};
    ${mix.height({ size: 4.5 })};
    ${mix.width({ size: 11 })};

    outline: none;
    border: none;
    border-radius: ${mix.unit({ size: 0.5 })};
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    transition: 300ms ease;
    pointer-events: auto;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    color: var(--gray-500);
    gap: ${mix.unit({ size: 1 })};
    background-color: white;
    font-family: 'Roboto', sans-serif;

    &:disabled {
        color: var(--gray-300);
        background-color: var(--gray-100);
    }

    &:hover:not([disabled]) {
        background-color: var(--gray-100);
    }
`;

const BackButton = styled(Button)`
    border: 1px solid;
    border-color: var(--gray-200);
`;

const NextButton = styled(Button)`
    background-color: var(--primary-500);
    color: white;

    &:hover:not([disabled]) {
        background-color: var(--primary-500);
    }
`;

const AddButton = styled(Button)`
    ${mix.padding({ padding: [1] })};
    border: 1px solid var(--primary-100);
    background-color: white;
    color: var(--primary-500);
    ${mix.width({ size: 17 })};
`;

const Search = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    justify-content: center;
    background-color: var(--gray-050);
    border-top: 1px solid var(--gray-200);
    border-bottom: 1px solid var(--gray-200);
    height: ${mix.unit({ size: 8 })};
    ${mix.padding({ padding: [0, 4] })};
`;

const InputGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    ${mix.gap({ size: 1 })}
`;

const Input = styled.input`
    padding: 0;
    width: 100%;
    outline: none;
    border: none;
    background: transparent;
    position: relative;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    color: var(--gray-600);
`;
