import { setup, fromPromise, assign } from 'xstate5';
import { api } from '$api';
import { Match } from '$api/dentally/match';
import {
    PatientState,
    PatientStateSchema,
    PatientLinkState,
} from '$state/concerns/dentally/patients';

interface DentallyContext {
    patientId?: string | null;
    dentallyId?: string | null;
    query?: string | null;
    patient?: PatientLinkState | null;
    suggestions?: Match[] | null;
    matches?: Match[] | null;
}

export const dentallyPanel = setup({
    types: {
        context: {} as DentallyContext,
        events: {} as
            | {
                  type: 'xstate.init';
                  input: {
                      patientId: string;
                  };
              }
            | { type: 'search'; query: string }
            | { type: 'searched'; matches: Match[] }
            | { type: 'link'; dentallyId: string; patientId: string }
            | { type: 'unlink'; patientId: string }
            | { type: 'xstate.done.actor.0.dentallyPanel.unlinking'; patient: PatientLinkState }
            | { type: 'suggest'; query: string }
            | { type: 'suggested'; suggestions: Match[]; query: string }
            | { type: 'linked'; patient: PatientLinkState }
            | { type: 'getPatient'; patient: PatientLinkState },
        output: {} as DentallyContext,
        input: {} as DentallyContext,
    },
    actors: {
        getPatient: fromPromise(
            async ({ input }: { input: { patientId: string } }) => {
                const { patientId } = input;
                const patient: PatientState = await api
                    .get(`/dentally/patients/${patientId}`)
                    .then(({ data }) => PatientStateSchema.parse(data));
                return {
                    type: 'getPatient',
                    patient: patient,
                } as const;
            },
        ),
        linkPatient: fromPromise(
            async ({
                input,
            }: {
                input: { patientId: string; dentallyId: string };
            }) => {
                const { dentallyId, patientId } = input;
                await api.post('/dentally/links', {
                    patient_id: patientId,
                    id: dentallyId,
                });
                const patient: PatientState = await api
                    .get(`/dentally/patients/${patientId}`)
                    .then(({ data }) => PatientStateSchema.parse(data));

                return {
                    type: 'linked',
                    patient: patient,
                } as const;
            },
        ),
        unlinkPatient: fromPromise(
            async ({ input }: { input: { patientId: string } }) => {
                const { patientId } = input;
                await api.delete(`/dentally/links/${patientId}`);
                return {
                    type: 'unlinked',
                    patient: { state: 'unlinked'},
                } as const;
            },
        ),
        getMatches: fromPromise(
            async ({ input }: { input: { query: string } }) => {
                const { query } = input;

                const matches = await api
                    .get(`/dentally/patients`, {
                        params: {
                            query: query,
                        },
                    })
                    .then(({ data }) => data);

                return {
                    type: 'searched',
                    matches: matches.matches as Match[],
                } as const;
            },
        ),
        getSuggestions: fromPromise(
            async ({ input }: { input: { query: string } }) => {
                const { query } = input;
                if(query.length < 3) {
                    return {
                        type: 'error',
                        query: query,
                    } as const;
                }
                const suggestions = await api
                    .get(`/dentally/patients`, {
                        params: {
                            query: query,
                            confidence: 0.05, // confidence threshold
                        },
                    })
                    .then(({ data }) => data);

                return {
                    type: 'suggested',
                    suggestions: suggestions.matches as Match[],
                    query: query,
                } as const;
            },
        ),
    },
}).createMachine({
    id: 'dentallyPanel',
    initial: 'loading',
    context: ({ input }) => ({
        patientId: input.patientId,
        dentallyId: input.dentallyId || null,
        patient: input.patient || null,
        query: input.query || '',
        suggestions: input.suggestions || null,
        matches: input.matches || null,
    }),
    states: {
        loading: {
            invoke: {
                src: 'getPatient',
                input: ({ event }) => {
                    if (event.type !== 'xstate.init') {
                        throw new Error(
                            'Type must be xstate.init, got ' + event.type,
                        );
                    }
                    return { patientId: event.input.patientId };
                },
                onDone: {
                    actions: assign(({ event }) => ({
                        patient: event.output.patient as PatientLinkState,
                        dentallyId:
                            (event.output.patient?.state === 'synced'
                                || event.output.patient?.state === 'syncing') ?
                            String(event.output.patient?.details?.id) : null,
                    })),
                    target: 'loaded',
                },
                onError: {
                    target: '#dentallyPanel.error',
                },
            },
        },
        loaded: {
            always: [
                {
                    target: 'linked.synced',
                    guard: ({ context }) =>
                        context.patient?.state === 'synced',
                },
                {
                    target: 'linked.syncing',
                    guard: ({ context }) =>
                        context.patient?.state === 'syncing',
                },
            ],
            on: {
                suggest: {
                    target: 'unlinked.suggest',
                    actions: assign({
                        query: ({ event }) => event.query,
                    }),
                },
            },
        },
        unlinked: {
            initial: 'suggest',
            states: {
                suggest: {
                    invoke: {
                        src: 'getSuggestions',
                        input: ({ event }) => {
                            if (event.type !== 'suggest') {
                                throw new Error(
                                    'Type must be suggest, got ' + event.type,
                                );
                            }
                            return {
                                query: event.query,
                            };
                        },
                        onDone: {
                            actions: [
                                assign(({ event }) => {
                                    return {
                                        query: event.output.query,
                                        suggestions: event.output.suggestions,
                                    };
                                }),
                            ],
                            target: 'suggested',
                        },
                        onError: {
                            target: '#dentallyPanel.error',
                        },
                    },
                },
                suggested: {
                    on: {
                        search: {
                            target: 'search',
                            actions: assign({
                                query: ({ event }) => event.query,
                            }),
                        },
                        link: {
                            target: 'linking',
                            actions: assign({
                                dentallyId: ({ event }) => event.dentallyId,
                            }),
                        },
                    },
                },
                search: {
                    invoke: {
                        src: 'getMatches',
                        input: ({ event }) => {
                            if (event.type !== 'search') {
                                throw new Error(
                                    'Type must be search, got ' + event.type,
                                );
                            }

                            return { query: event.query };
                        },
                        onDone: {
                            actions: assign(({ event }) => {
                                return {
                                    matches: event.output.matches as Match[],
                                };
                            }),
                            target: 'searched',
                        },
                        onError: {
                            target: '#dentallyPanel.error',
                        },
                    },
                    on: {
                        reset: 'suggest',
                        link: 'linking',
                    },
                },
                searched: {
                    on: {
                        link: {
                            actions: assign({
                                dentallyId: ({ event }) => event.dentallyId,
                            }),
                            target: 'linking',
                        },
                        search: {
                            target: 'search',
                            actions: assign({
                                query: ({ event }) => event.query,
                            }),
                        },
                    },
                },
                linking: {
                    invoke: {
                        src: 'linkPatient',
                        input: ({ context }) => ({
                            patientId: context.patientId as string,
                            dentallyId: context.dentallyId as string,
                        }),
                        onDone: {
                            actions: assign(({ event }) => ({
                                patient: event.output.patient as PatientLinkState,
                            })),
                            target: '#dentallyPanel.linked.load',
                        },
                        onError: {
                            target: '#dentallyPanel.error',
                        },
                    },
                },
            },
        },
        linked: {
            initial: 'load',
            states: {
                load: {
                    always: [
                        {
                            target: '#dentallyPanel.linked.synced',
                            guard: ({ context }) =>
                                context.patient?.state === 'synced',
                        },
                        {
                            target: '#dentallyPanel.linked.syncing',
                            guard: ({ context }) =>
                                context.patient?.state === 'syncing',
                        }
                    ],
                },
                syncing: {},
                synced: {},
            },
            on: {
                unlink: {
                    target: 'unlinking',
                    actions: assign({
                        patientId: ({ event }) => event.patientId,
                    }),
                },
            },
        },
        unlinking: {
            invoke: {
                src: 'unlinkPatient',
                input: ({ context }) => ({
                    patientId: context.patientId as string,
                }),
                onDone: {
                    target: '#dentallyPanel.loaded',
                    actions: assign({
                        patient: { state: 'unlinked' },
                    }),
                },
                onError: {
                    target: '#dentallyPanel.error',
                },
            },
        },
        error: {
            on: {
                search: {
                    target: '#dentallyPanel.unlinked.search',
                    actions: assign({
                        query: ({ event }) => event.query,
                    }),
                },
            },
        },
    },
    output: ({ context }) =>
        ({
            patientId: context.patientId,
            dentallyId: context.dentallyId,
            patient: context.patient,
            query: context.query,
            suggestions: context.suggestions,
            matches: context.matches,
        } as DentallyContext),
});
