import {
    bulkMarkConversationRead,
    bulkMarkConversationStarred,
    bulkMarkConversationUnread,
    bulkMarkConversationUnstarred,
    fetchConversations,
    markConversationRead,
    markConversationStarred,
    markConversationUnread,
    markConversationUnstarred,
    resetConversation,
} from '$state/concerns/inbox';
import { useAppDispatch, useAppSelector } from '$state/hooks';
import {
    conversationsMap,
    conversationsToken,
    getFilters,
    getUnreadCountWithFilters,
    getViewState,
    inboxLoaded,
    loadingNextConversations,
} from '$state/queries/inbox';
import {
    Conversation as ConversationSchema,
    ConversationsStatus,
} from '$state/types/inbox';
import {
    InboxChannelFilter,
    InboxStatusFilter,
    SnoozedStatus,
    SortOrder,
    StarredStatus,
} from '$types/app';
import { CommType } from '$types/patient';
import { TimelineContainer as Activities } from '$ui/Inbox/TimelineContainer';
import { Heading } from '$ui/Flo/Heading';
import { Icon } from '$ui/Flo/Icon';
import { Text } from '$ui/Flo/Text';
import { mix, scale } from '$ui/Flo/util';
import { Patient } from '$ui/Patient/Patient';
import React from 'react';
import styled, { css } from 'styled-components';
import { Channels } from '$state/types';
import { withState } from '$state';
import { usePageTitle } from '@/utils/hooks';
import {
    BulkActionBar,
    ConnectedFilterBar,
    TimelineActionBar,
} from '$ui/Inbox';
import { getTxTypes } from '$state/concerns/client';
import { lang } from '$utils';
import { InboxList } from '$ui/Inbox/InboxList';
import { refreshTimeline } from '$state/concerns/timeline';
import { integrationHealthy } from '$state/concerns/integrations';
import { selectTimelineRefreshing } from '$state/queries/timeline';
import { epoch } from '@/utils/date';

interface Props {
    name: string;
    status?: InboxStatusFilter;
    channel?: InboxChannelFilter;
}

interface ConversationSet {
    [patientId: string]: ConversationSchema;
}

export const Inbox = ({ name, channel = CommType.Email }: Props) => {
    const dispatch = useAppDispatch();

    usePageTitle(`${name} - Inbox`);

    // This should be refactored to a dedicated query
    const dentallyHealthy = useAppSelector(
        withState(integrationHealthy, 'dentally'),
    );
    const showIntegrationBar = dentallyHealthy;

    // convoStaging is a local conversation staging area. We store
    // conversations here that have been read or unread, but we still want to
    // keep around until the user changes to another view, and then we can
    // flush them out

    const [convoStaging, setConvoStaging] = React.useState<ConversationSet>({});
    const view = useAppSelector(withState(getViewState, name));
    const failed = view.state === ConversationsStatus.ERRORED;
    const loaded = useAppSelector(withState(inboxLoaded, name));
    const filters = useAppSelector(withState(getFilters, name));

    // We need conversations to be in a map/set so that
    // we can merge it with some local-component held conversations
    // so that we aren't making duplicates
    const conversations = useAppSelector(
        withState(conversationsMap, { channel }),
    );

    // Pagination
    const loadingNext = useAppSelector(
        withState(loadingNextConversations, name),
    );
    const token = useAppSelector(withState(conversationsToken, name));
    const [unread, setUnread] = React.useState<InboxStatusFilter>('all');
    const [starred, setStarred] = React.useState<StarredStatus>('all');
    const [snoozed, setSnoozed] = React.useState<SnoozedStatus>('hideSnoozed');
    const unreadCount = useAppSelector(
        withState(getUnreadCountWithFilters, name),
    );

    const fetchPage = (token: string | null = null) => {
        dispatch(
            fetchConversations({
                view: name,
                unread,
                channel,
                token,
                sortDirection: sort,
                startAt: range.start.toISOString(),
                endAt: range.end.toISOString(),
                types: selectedTypes,
                starred,
                snoozed,
            }),
        );
    };

    // Selection
    const [selected, setSelected] = React.useState<ConversationSchema | null>(
        null,
    );
    // Expand
    const [expanded, setExpanded] = React.useState(true);
    // Date picker range
    const [range, setRange] = React.useState<{
        start: Date;
        end: Date;
    }>({
        start: epoch,
        end: new Date(),
    });
    // sort direction
    const [sort, setSort] = React.useState<SortOrder>('desc');
    // types
    const defaultTypes = useAppSelector(withState(getTxTypes));
    const [selectedTypes, setSelectedTypes] = React.useState<string[]>([]);
    // checked conversations for bulk actions
    const [checked, setChecked] = React.useState<string[]>([]);

    const isTimelineRefreshing = useAppSelector(
        withState(selectTimelineRefreshing, selected?.patient_id ?? ''),
    );

    // Fetching operations
    React.useEffect(() => {
        if (loadingNext) {
            return;
        }
        handleRefresh();
    }, [sort, range, selectedTypes, unread, starred, snoozed]);

    // When the user changes view, clear out the read staging area.
    React.useEffect(() => {
        return () => {
            setConvoStaging({});
            handleRefresh();
        };
    }, [channel]);

    // open chat on selected conversation
    const onConvoSelected = React.useCallback(
        (conv: ConversationSchema) => {
            if (conv.patient_id === selected?.patient_id) {
                return;
            }

            setSelected({ ...conv, unread: false, unread_count: 0 });

            setExpanded(false);

            if (!conv.unread) {
                // The convo has already been read
                return;
            }
            // Append this unread conversation to the read conversation staging
            // area, simultaneously setting it as read (only locally)
            setConvoStaging((convoStaging) => ({
                ...convoStaging,
                ...{
                    [conv.patient_id]: {
                        ...conv,
                        unread: false,
                        unread_count: 0,
                    },
                },
            }));
        },
        [selected],
    );

    const inboxEmpty =
        Object.keys(conversations).length == 0 &&
        Object.keys(convoStaging).length == 0;

    const handleRange = (range: { start: Date; end: Date }) => {
        if (!loaded || loadingNext) return;
        setRange(range);
    };

    const handleSort = (sort: SortOrder) => {
        if (!loaded || loadingNext) return;
        setSort(sort);
    };

    const handleSelectType = (types: string | string[]) => {
        if (!loaded || loadingNext) return;
        setSelectedTypes(Array.isArray(types) ? types : [types]);
    };

    const handleUnreadClick = () => {
        if (!loaded || loadingNext) return;

        if (unread === 'all') {
            setUnread('unread');
        } else {
            setUnread('all');
        }
    };

    const handleStarredClick = () => {
        if (!loaded || loadingNext) return;

        if (starred === 'all') {
            setStarred('starred');
        } else {
            setStarred('all');
        }
    };

    const handleSnoozedClick = () => {
        if (!loaded || loadingNext) return;
        if (snoozed === 'hideSnoozed') {
            setSnoozed('showSnoozed');
        } else {
            setSnoozed('hideSnoozed');
        }
    };

    const handleCheck = React.useCallback((id: string) => {
        setChecked((c) => {
            if (c.includes(id)) {
                return c.filter((item) => item !== id);
            }
            return [...c, id];
        });
    }, []);

    const handleCheckAll = () => {
        setChecked(Object.keys(conversations));
    };

    const handleUncheck = React.useCallback((id: string) => {
        setChecked((c) => c.filter((item) => item !== id));
    }, []);

    const handleUncheckAll = React.useCallback(() => {
        setChecked([]);
    }, []);

    const [containsRead, setContainsRead] = React.useState(false);
    const [containsUnread, setContainsUnread] = React.useState(false);
    const [containsStarred, setContainsStarred] = React.useState(false);
    const [containsUnstarred, setContainsUnstarred] = React.useState(false);

    React.useEffect(() => {
        const convos = Object.values({ ...conversations, ...convoStaging });

        const check = convos.filter((convo) =>
            checked.includes(convo.patient_id),
        );

        setContainsRead(check.some((convo) => !convo.unread));
        setContainsUnread(check.some((convo) => convo.unread));
        setContainsStarred(check.some((convo) => convo.starred));
        setContainsUnstarred(check.some((convo) => !convo.starred));
    }, [checked, conversations, convoStaging]);

    const handleBulkMarkRead = () => {
        if (selected && checked.includes(selected.patient_id)) {
            setSelected({
                ...selected,
                unread: false,
                unread_count: 0,
            });
        }

        const allConvo = { ...conversations, ...convoStaging };

        const convoToRead = checked.reduce((acc, id) => {
            if (allConvo[id] && allConvo[id].unread) {
                acc[id] = {
                    ...allConvo[id],
                    unread: false,
                    unread_count: 0,
                };
            }
            return acc;
        }, {});

        setConvoStaging({
            ...convoStaging,
            ...convoToRead,
        });

        dispatch(
            bulkMarkConversationRead({
                patientIds: Object.keys(convoToRead),
            }),
        );
    };

    const handleBulkMarkUnread = () => {
        if (selected && checked.includes(selected.patient_id)) {
            setSelected({
                ...selected,
                unread: true,
                unread_count: 1,
            });
        }

        const allConvo = { ...conversations, ...convoStaging };

        const convoToUnread = checked.reduce((acc, id) => {
            if (allConvo[id] && !allConvo[id].unread) {
                acc[id] = {
                    ...allConvo[id],
                    unread: true,
                    unread_count: 1,
                };
            }
            return acc;
        }, {});

        setConvoStaging({
            ...convoStaging,
            ...convoToUnread,
        });

        dispatch(
            bulkMarkConversationUnread({
                patientIds: Object.keys(convoToUnread),
            }),
        );
    };

    const handleRefresh = () => {
        dispatch(resetConversation(channel));
        fetchPage();
        setSelected(null);
        setExpanded(true);
        setChecked([]);
        setConvoStaging({});
    };

    const handleSingleMarkAsRead = React.useCallback(
        (id: string) => {
            setSelected((s) =>
                s && s.patient_id === id
                    ? { ...s, unread: false, unread_count: 0 }
                    : s,
            );

            setConvoStaging((c) => ({
                ...c,
                ...{
                    [id]: {
                        ...conversations[id],
                        unread: false,
                        unread_count: 0,
                    },
                },
            }));

            dispatch(markConversationRead(id));
        },
        [conversations],
    );

    const handleSingleMarkAsUnread = React.useCallback(
        (id: string) => {
            setSelected((s) =>
                s && s.patient_id === id
                    ? { ...s, unread: true, unread_count: 1 }
                    : s,
            );

            setConvoStaging((c) => ({
                ...c,
                ...{
                    [id]: {
                        ...conversations[id],
                        unread: true,
                        unread_count: 1,
                    },
                },
            }));

            dispatch(markConversationUnread(id));
        },
        [conversations],
    );

    const handleSingleMarkAsStarred = React.useCallback(
        (id: string) => {
            setSelected((s) =>
                s && s.patient_id === id ? { ...s, starred: true } : s,
            );

            setConvoStaging((c) => ({
                ...c,
                ...{ [id]: { ...conversations[id], starred: true } },
            }));

            dispatch(markConversationStarred(id));
        },
        [conversations],
    );

    const handleSingleMarkAsUnstarred = React.useCallback(
        (id: string) => {
            setSelected((s) =>
                s && s.patient_id === id ? { ...s, starred: false } : s,
            );

            setConvoStaging((c) => ({
                ...c,
                ...{ [id]: { ...conversations[id], starred: false } },
            }));

            dispatch(markConversationUnstarred(id));
        },
        [conversations],
    );

    const handleBulkMarkAsStarred = () => {
        if (selected && checked.includes(selected.patient_id)) {
            setSelected({
                ...selected,
                starred: true,
            });
        }

        const allConvo = { ...conversations, ...convoStaging };

        const convoToStarred = checked.reduce((acc, id) => {
            if (allConvo[id] && !allConvo[id].starred) {
                acc[id] = {
                    ...allConvo[id],
                    starred: true,
                };
            }

            return acc;
        }, {});

        setConvoStaging({
            ...convoStaging,
            ...convoToStarred,
        });

        dispatch(
            bulkMarkConversationStarred({
                patientIds: Object.keys(convoToStarred),
            }),
        );
    };

    const handleBulkMarkAsUnstarred = () => {
        if (selected && checked.includes(selected.patient_id)) {
            setSelected({
                ...selected,
                starred: false,
            });
        }

        const allConvo = { ...conversations, ...convoStaging };

        const convoToUnstarred = checked.reduce((acc, id) => {
            if (allConvo[id] && allConvo[id].starred) {
                acc[id] = {
                    ...allConvo[id],
                    starred: false,
                };
            }

            return acc;
        }, {});

        setConvoStaging({
            ...convoStaging,
            ...convoToUnstarred,
        });

        dispatch(
            bulkMarkConversationUnstarred({
                patientIds: Object.keys(convoToUnstarred),
            }),
        );
    };

    const checkedInView = checked.filter((id) => conversations[id]).length;

    return (
        <Grid>
            <InboxCell expanded={expanded}>
                <InboxWrapper>
                    <FilterBarWrapper>
                        <ConnectedFilterBar
                            name={name}
                            channel={channel}
                            expanded={expanded}
                            unread={unreadCount}
                            sort={sort}
                            range={range}
                            types={defaultTypes ?? []}
                            selectedTypes={selectedTypes}
                            onDateChange={handleRange}
                            onSortChange={handleSort}
                            onStarredClick={handleStarredClick}
                            onSnoozedClick={handleSnoozedClick}
                            onTypeChange={handleSelectType}
                            onUnreadClick={handleUnreadClick}
                            active={{
                                unread:
                                    filters.unread === 'unread' &&
                                    unread === 'unread',
                                starred:
                                    filters.starred === 'starred' &&
                                    starred === 'starred',
                                snoozed:
                                    filters.snoozed === 'showSnoozed' &&
                                    snoozed === 'showSnoozed',
                            }}
                        />
                    </FilterBarWrapper>
                    {failed && <FailedList />}
                    {loaded && inboxEmpty && <EmptyList />}
                    <InboxListWrapper>
                        <InboxList
                            onSelect={onConvoSelected}
                            conversations={Object.values({
                                ...conversations,
                                ...convoStaging,
                            })}
                            selected={selected?.patient_id}
                            onCheck={handleCheck}
                            onUncheck={handleUncheck}
                            onMarkAsRead={handleSingleMarkAsRead}
                            onMarkAsUnread={handleSingleMarkAsUnread}
                            onStarred={handleSingleMarkAsStarred}
                            onUnstarred={handleSingleMarkAsUnstarred}
                            loading={!loaded}
                            checked={checked}
                            expanded={expanded}
                            fetchNextPage={fetchPage}
                            loadingNext={loadingNext}
                            token={token}
                            channel={channel}
                        />
                    </InboxListWrapper>
                </InboxWrapper>
                {loaded && !inboxEmpty && (
                    <BulkActionBarWrapper>
                        <BulkActionBar
                            checked={checkedInView > 0}
                            read={containsRead}
                            unread={containsUnread}
                            starred={containsStarred}
                            unstarred={containsUnstarred}
                            expanded={expanded}
                            onSelectAll={handleCheckAll}
                            onDeselect={handleUncheckAll}
                            onMarkAsRead={handleBulkMarkRead}
                            onMarkAsUnread={handleBulkMarkUnread}
                            onMarkAsStarred={handleBulkMarkAsStarred}
                            onMarkAsUnstarred={handleBulkMarkAsUnstarred}
                            onRefresh={handleRefresh}
                            selected={checkedInView}
                        />
                    </BulkActionBarWrapper>
                )}
            </InboxCell>
            <ExpandButtonWrapper>
                {selected && (
                    <ExpandButton onClick={() => setExpanded(!expanded)}>
                        {expanded && (
                            <Icon
                                icon="ChevronLeft"
                                size={2}
                                hue="grey"
                                shade="1"
                                clickable
                            />
                        )}
                        {!expanded && (
                            <Icon
                                icon="ChevronRight"
                                size={2}
                                hue="grey"
                                shade="1"
                                clickable
                            />
                        )}
                    </ExpandButton>
                )}
            </ExpandButtonWrapper>

            {lang.isEmpty(selected) && <EmptyInbox />}
            {selected && (
                <>
                    {!expanded && (
                        <TimelineCell>
                            <TimelineActionBar
                                refreshing={isTimelineRefreshing}
                                read={!selected.unread}
                                starred={selected.starred}
                                onStarred={() =>
                                    handleSingleMarkAsStarred(
                                        selected.patient_id,
                                    )
                                }
                                onUnstarred={() =>
                                    handleSingleMarkAsUnstarred(
                                        selected.patient_id,
                                    )
                                }
                                onRefresh={() =>
                                    dispatch(
                                        refreshTimeline(selected.patient_id),
                                    )
                                }
                                onMarkAsRead={() =>
                                    handleSingleMarkAsRead(selected.patient_id)
                                }
                                onMarkAsUnread={() =>
                                    handleSingleMarkAsUnread(
                                        selected.patient_id,
                                    )
                                }
                            />
                            <Activities
                                patientId={selected.patient_id}
                                selectChannelAfterLoad={selectedChannel(
                                    selected,
                                )}
                                showRefreshButton={false}
                            />
                        </TimelineCell>
                    )}
                    <PatientCell showIntegrationBar={showIntegrationBar}>
                        <Patient
                            id={selected.patient_id}
                            barPosition={'right'}
                        />
                    </PatientCell>
                </>
            )}
        </Grid>
    );
};

const FailedList = () => (
    <Empty>
        <Icon icon="Frown" size={8} hue="red" />
        <Sep />
        <Text profile="secondary" level="body2">
            Oops! Something went wrong. You should try refreshing
        </Text>
    </Empty>
);

const EmptyList = () => (
    <Empty>
        <Icon icon="Smile" size={8} hue="type" />
        <Sep />
        <Text profile="secondary" level="body2">
            There's nothing left to do. Any new patient conversations will
            appear here
        </Text>
    </Empty>
);

const EmptyInbox = () => (
    <Empty>
        <Icon icon="Inbox" size={6} hue="primary" />
        <Sep />
        <Heading level="subtitle1">Welcome to the Inbox</Heading>
        <Sep />
        <Text level="subtitle2" profile="secondary">
            Click any conversation on the left to open it and reply to the
            patient
        </Text>
    </Empty>
);

const Empty = styled.div`
    display: flex;
    height: 100%;
    width: 100%;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    ${mix.padding({ padding: [4] })};
    background: white;
    border-left: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
`;

const Sep = styled.span`
    width: 100%;
    height: ${({ theme }) => scale(theme, 2)}px;
`;

const Grid = styled.div`
    display: flex;
    flex-direction: row;
    align-items: stretch;
    height: calc(100vh - 54px);
    ${mix.type({ level: 'body2' })};
    width: 100%;
`;

const InboxCell = styled.div<{ expanded: boolean }>`
    background: white;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    overflow-y: auto;
    min-width: 350px;
    width: ${({ expanded }) => (expanded ? '100%' : '30%')};
    position: relative;
    ${({ expanded }) =>
        !expanded
            ? css`
                  flex: 0 0 25%;
              `
            : css`
                  flex: 0 0 75%;
                  border: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
              `};
`;

const TimelineCell = styled.div`
    display: flex;
    flex-direction: column;
    background: white;
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    border-top: 0;
    min-width: 300px;
    flex: 2;
    position: relative;
`;

const PatientCell = styled.div<{ showIntegrationBar: boolean }>`
    background: white;
    min-width: 300px;
    flex: ${({ showIntegrationBar }) => (showIntegrationBar ? '1.25' : '1')};
`;

const selectedChannel = (
    selectedConvo: ConversationSchema | null,
): Channels | undefined => {
    if (!selectedConvo) {
        return undefined;
    }

    const channel = selectedConvo.channel.toLowerCase() as Channels;

    return Object.values(Channels).includes(channel) ? channel : undefined;
};

const ExpandButtonWrapper = styled.div`
    position: relative;
`;

const ExpandButton = styled.div`
    z-index: 1;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%) translateX(50%);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 1px solid ${mix.palette({ hue: 'grey', shade: '9' })};
    background: white;
    line-height: 1;
    cursor: pointer;
    ${mix.shadow({ level: '1' })};
`;

const FilterBarWrapper = styled.div`
    position: sticky;
    top: 0;
    z-index: 100;
`;

const BulkActionBarWrapper = styled.div`
    position: sticky;
    bottom: 0;
    z-index: 100;
`;

const InboxWrapper = styled.div`
    flex: 1 1;
    display: flex;
    flex-direction: column;
`;

const InboxListWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;
