import React, { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { ColorToken, Hue, ThemeType } from '$ui/Flo/types';

interface CircularProps {
    radius: number;
    thickness?: number;
    rates: {
        success: number;
        failure?: number;
    };
    rounded?: boolean;
    hues?: {
        track?: Hue | ColorToken | 'none';
        success?: Hue | ColorToken | 'none';
        failure?: Hue | ColorToken;
    };
}

export const Circular = (props: CircularProps) => {
    const {
        rates: { success, failure = 0 },
        rounded,
        hues,
    } = props;

    const theme = useContext(ThemeContext) as ThemeType;
    const thickness = theme.unit * (props.thickness ?? 1);
    const radius = theme.unit * props.radius;
    const size = 2 * radius;
    const successEndAngle = 360 * success;
    const failureEndAngle = 360 * (failure + success);

    if (success + failure > 1) {
        throw new Error('Sum of rates cannot be greater than 1');
    }

    const getColor = (hue: Hue | ColorToken | 'none', level: string) => {
        if (hue.includes('-')) {
            return `var(--${hue})`;
        }

        if (hue !== 'none') {
            return theme.color[hue][level];
        }

        return '';
    }

    return (
        <svg viewBox={`0 0 ${size} ${size}`} width={size} height={size}>
            <Circle
                radius={radius}
                thickness={thickness}
                color={getColor(hues?.track ? hues.track : 'none', '1')}
            />
            <Segment
                startAngle={0}
                endAngle={successEndAngle}
                radius={radius}
                color={getColor(hues?.success ? hues.success : 'none', '6')}
                thickness={thickness}
                rounded={rounded}
            />
            <Segment
                startAngle={successEndAngle}
                endAngle={failureEndAngle}
                radius={radius}
                color={getColor(hues?.failure ? hues.failure : 'none', '6')}
                thickness={thickness}
            />
        </svg>
    );
};

interface CircleProps {
    radius: number;
    thickness: number;
    color: string;
    rounded?: boolean;
}

const Circle = ({ radius, thickness, color, rounded }: CircleProps) => {
    return (
        <circle
            cx={radius}
            cy={radius}
            r={thicknessAdjustedRadius(radius, thickness)}
            fill="transparent"
            stroke={color}
            strokeWidth={thickness}
            strokeLinecap={rounded ? 'round' : 'butt'}
        />
    );
};

interface SegmentProps {
    startAngle: number;
    endAngle: number;
    radius: number;
    color: string;
    thickness: number;
    rounded?: boolean;
}

const Segment = (props: SegmentProps) => {
    const { startAngle, endAngle, radius, color, thickness, rounded } = props;

    // if we've come full circle
    if (endAngle === 360 && startAngle !== endAngle) {
        return (
            <Circle
                radius={radius}
                thickness={thickness}
                color={color}
                rounded={rounded}
            />
        );
    }

    const largeArcFlag = endAngle - startAngle <= 180 ? '0' : '1';

    const realRadius = thicknessAdjustedRadius(radius, thickness);

    const [startX, startY] = polarToCartesian({
        cx: radius,
        cy: radius,
        r: realRadius,
        a: startAngle,
    });

    const [endX, endY] = polarToCartesian({
        cx: radius,
        cy: radius,
        r: realRadius,
        a: endAngle,
    });

    return (
        <path
            d={`M ${startX},${startY} A${realRadius},${realRadius} 0 ${largeArcFlag} 1 ${endX},${endY}`}
            fill="none"
            stroke={color}
            strokeWidth={thickness}
            strokeLinecap={rounded ? 'round' : 'butt'}
        />
    );
};

const thicknessAdjustedRadius = (r: number, thickness: number) =>
    r - thickness / 2;

interface PolarToCartesianArgs {
    cx: number;
    cy: number;
    r: number;
    a: number;
}

/**
 * from: https://stackoverflow.com/a/5737245/1305558
 */
const polarToCartesian = ({ cx, cy, r, a }: PolarToCartesianArgs) => {
    const angleInRadians = ((a - 90) * Math.PI) / 180.0;
    const x = cx + r * Math.cos(angleInRadians);
    const y = cy + r * Math.sin(angleInRadians);
    return [x, y];
};
